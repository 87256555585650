<template>
  <div>
    <v-simple-table v-if="!!packets">
        <thead>
        <th></th>
        <th>caid</th>
        <th>ident</th>
        </thead>
        <tbody>
        <tr v-for="p in packets">
            <td><strong class="yellow--text">{{p.name}}</strong></td>
          <td>{{p.caid}}</td>
          <td>{{p.ident}}</td>
        </tr>
        </tbody>
    </v-simple-table>
  </div>
</template>

<script>
    export default {
        name: "PacketList",
        props: {
            packets : {
              type : Array,
            },
            ports : {
              type : Object,
            }
        },
    }
</script>

<style scoped>

</style>