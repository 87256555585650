<template>
  <v-dialog v-model="showed" overlay-opacity="0.9" max-width="1100" persistent>
    <v-toolbar dense>
      <v-toolbar-title>
        Playlists
      </v-toolbar-title>
      <v-spacer />
      <v-toolbar-items>
        <v-btn icon @click="showed = false"><v-icon>mdi-close</v-icon></v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-card>
      <v-card-text>
<!--        <v-btn @click="$refs.playlistEditDialog.open()"  block color="orange">EDIT PLAULISTS</v-btn>-->
        <playlists :id="accountId" />
      </v-card-text>
    </v-card>
<!--    <playlist-edit-dialog ref="playlistEditDialog" :accountID="accountId" />-->
  </v-dialog>
</template>

<script>
import Playlists from "./Playlists";
import PlaylistEditDialog from "@/components/iptv/PlaylistEditDialog";
export default {
  name: "PlaylistsDialog",
  components: {PlaylistEditDialog, Playlists},
  props: {
    accountId: {
      type: String
    },
  },
  data() {
    return {
      showed: false
    }
  },
}
</script>

<style scoped>

</style>