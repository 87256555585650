import {networker} from "../http";

const ChatService = {
    getMessages(page){
        return networker.get(`chat/${page}`)
    },
    send(msg){
        return networker.post(`chat`,msg)
    }
}

export default ChatService