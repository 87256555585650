<template>
    <div>
      <v-btn block="$vuetify.breakpoint.isMobile" class="mb-1" large color="pink" to="/balance">
        {{$t('price.purchase')}} <v-icon right>mdi-plus</v-icon>
      </v-btn>
        <div>
            <v-text-field dense clearable outlined v-model="filters.word" :label="$t('base.search')" prepend-inner-icon="mdi-magnify" />
            <v-row>
                <v-col cols="12" sm="6">
                    <date-chooser clearable v-model="filters.from" :label="$t('log.filter_from')"></date-chooser>
                </v-col>
                <v-col cols="12" sm="6">
                    <date-chooser clearable v-model="filters.to"   :label="$t('log.filter_to')"></date-chooser>
                </v-col>
            </v-row>
        </div>
        <v-data-table
                dense
                class="elevation-2"
                :headers="headers"
                :items="items"
                :options.sync="options"
                :server-items-length="total"
                sort-by="created"
                sort-desc="true"
                :footer-props="{'items-per-page-options': [20,50,100,200,500]}"
                :loading="loading"
        >
            <template v-slot:item.amount="{item}">
<!--                <v-chip light text-color="black" small class="font-weight-bold" :color="item.amount < 0 ? 'red' : 'green'">-->
<!--                    <v-avatar left>-->
<!--                        <v-tooltip top>-->
<!--                            <template v-slot:activator="{ on }">-->
<!--                                    <v-icon  small v-on="on" color="black">{{getStatus(item.action).icon}}</v-icon>-->
<!--                            </template>-->
<!--                            <span>{{$t(`transaction.status.${item.action}`)}}</span>-->
<!--                        </v-tooltip>-->
<!--                    </v-avatar>-->
<!--                    {{item.amount | currencyFilter}}-->
<!--                </v-chip>-->
                <div :class="item.amount < 0 ? 'red--text' : 'green--text'" class="font-weight-bold" >{{item.amount | currencyFilter}}</div>
            </template>
        </v-data-table>
    </div>
</template>

<script>
    import DateChooser from "../chooser/DateChooser";
    import UserService from "../../service/api/userService";
    export default {
        name: "user-transactions",
        components: {DateChooser},
        props: {
        },
        watch: {
            filters: {
                handler () {
                    this.getDataFromApi()
                },
                deep: true,
            },
            options: {
                handler () {
                    this.getDataFromApi()
                },
                deep: true,
            },
        },
      mounted() {
          this.getDataFromApi()
      },
      data() {
            return {
                filters : {
                    word : "",
                    from : null,
                    to : null,
                },
                loading: false,
                headers : [
                    {value : 'created',text : this.$t('base.time')},
                    {value : 'amount',text : this.$t('base.amount')},
                    {value : 'description',text : this.$t('base.desc')},
                ],
                status : {
                    PAY: {
                        icon : 'mdi-cart',
                        text : 'pay'
                    },
                    REFUND: {
                        icon : 'mdi-cash-usd',
                        text : 'ref'
                    },
                    MANUAL: {
                        icon : 'mdi-hand-pointing-right',
                        text : 'manu'
                    },
                    AUTO: {
                      icon : 'mdi-hand-pointing-right',
                      text : 'manu'
                    },
                },
                items : [],
                total : 0,
              options : {},
            }
        },
        methods: {
            getStatus(k){
              return this.status[k]
            },
            getDataFromApi() {
                const { sortBy, descending, page, itemsPerPage } = this.options
                this.loading = true
                UserService.getTransactionHistory(this.options,this.filters)
                    .then(res => {
                        this.items = res.data.content
                        this.total  = res.data.totalElements
                        //this.options.page = res.pageNumber
                    })
                    .catch(err => {
                        this.$swal(this.$t('errors.error'),'','error')
                    })
                    .finally(() => this.loading = false)
            }
        },
    }
</script>

<style scoped>

</style>