<template>
    <v-card>
        <v-card-text>
            <user-form :user-id="userId"></user-form>
        </v-card-text>
    </v-card>
</template>

<script>
    import UserForm from "@/components/user/UserForm";
    export default {
        name: "user-info",
        components: {UserForm},
        computed: {
            userId() {
                return this.$route.params.id;
            }
        },
    }
</script>

<style scoped>

</style>