<template>
    <div>
        <v-text-field v-if="accounts.length > 1 || filter != null"
                      :label="$t('base.search')"
                      v-model="filter"
                      dense
                      @keydown.enter="getAccounts"
                      outlined clearable
                      prepend-inner-icon="mdi-magnify">
            <template v-slot:append>
                <v-btn x-small @click="getAccounts" color="pink">{{$t('base.search')}}</v-btn>
            </template>
        </v-text-field>
        <v-overlay opacity="0.8" v-model="loading">
            <v-progress-circular color="yellow" indeterminate size="60" />
        </v-overlay>
        <div v-if="accounts.length > 1">
          <packet-list-extended v-if="isCS" ref="availablePacketList"    :account-id="viewedAccount ? viewedAccount.id : ''" :key="!viewedAccount ? 'pl' : `${viewedAccount.id}pl`" />
          <config-instruction   v-if="isCS" ref="deviceConfig"           :account-id="viewedAccount ? viewedAccount.id : ''" :key="!viewedAccount ? 'dc' : `${viewedAccount.id}dc`" />
          <v-data-table :items="accounts"
                        class="rounded-lg"
                          dense
                          :loading="loading"
                          item-key="account.id"
                          :server-items-length="total"
                          :headers = "headers"
                          :options.sync="options">
                <template v-slot:item.enabled="{item}">
                    <v-icon  :color="getWatchColor(item)">mdi-monitor</v-icon>

                    <v-tooltip top v-if="item.soonExpiredSubscription">
                        <template v-slot:activator="{on}">
                            <v-icon v-on="on" color="red">mdi-alert-outline</v-icon>
                        </template>
                        <span>{{$t('packet.soon_expired')}}</span>
                    </v-tooltip>
                    <v-tooltip top v-if="item.account.autoContinue">
                        <template v-slot:activator="{on}">
                            <v-icon color="green" v-on="on" >mdi-reload</v-icon>
                        </template>
                        <span>{{$t('users.auto_continue')}}</span>
                    </v-tooltip>
                </template>
                <template v-slot:item.createDateTime="{item}">
                    {{item.account.createDateTime}}
                </template>
                <template v-slot:item.label="{item}">
                    {{item.account.label}}
                </template>
                <template v-slot:header.subs>
                    <div>{{$t('packet.packets')}}</div>
                    <div>
                        <span class="green--text">{{$t('packet.active_small')}}</span>
                        / <span class="yellow--text">{{$t('packet.paused')}}</span>
                        / <span class="blue--text">{{$t('packet.deferred')}}</span>
                    </div>
                </template>
                <template v-slot:item.subs="{item}">
                    <v-btn @click="openSubsDialog(item.account)" rounded  elevation="4">
                        <v-icon small left>mdi-cart</v-icon>
                        <span class="green--text">{{item.packetState.active}}</span>
                        / <span class="yellow--text">{{item.packetState.paused}}</span>
                        / <span class="blue--text">{{item.packetState.deferred}}</span>
                    </v-btn>
                </template>
                <template v-slot:item.until="{item}">
                    <div class="orange--text font-weight-bold">{{item.packetState.until}}</div>
                </template>
                <template v-slot:item.login="{item}">
                    <span class="yellow--text">{{item.account.login}}</span>
                </template>
                <template v-slot:item.password="{item}">
                    <span class="yellow--text">{{item.account.password}}</span>
                </template>
                <template v-slot:item.actions="{item}">
                  <v-tooltip top v-if="item.packetState.active > 0 && isCS">
                    <template v-slot:activator="{on}">
                      <v-btn small icon v-on="on" color="orange lighten-1"
                             @click="openPacketsDialog(item.account)">
                        <v-icon>mdi-format-list-bulleted</v-icon>
                      </v-btn>
                    </template>
                    <span>{{$t('packet.packets')}}</span>
                  </v-tooltip>
                  <v-tooltip top v-if="item.packetState.active > 0 && isCS">
                    <template v-slot:activator="{on}">
                      <v-btn small icon v-on="on" color="orange-deep"
                             @click="openConfigDialog(item.account)">
                        <v-icon>mdi-tablet</v-icon>
                      </v-btn>
                    </template>
                    <span>{{$t('help.device_info')}}</span>
                  </v-tooltip>
                    <v-tooltip top>
                        <template v-slot:activator="{on}">
                            <v-btn small icon v-on="on" color="pink lighten-1"
                                   @click="openInfoDialog(item.account)">
                                <v-icon>mdi-launch</v-icon>
                            </v-btn>
                        </template>
                        <span>{{$t('base.full')}}</span>
                    </v-tooltip>
                </template>
            </v-data-table>
            <v-dialog v-model="packetsDialog" persistent >
                <div v-if="viewedAccount" >
                    <v-toolbar  tile dense flat>
                        <v-toolbar-title>{{viewedAccount.label}} [{{viewedAccount.login}}]</v-toolbar-title>
                        <v-spacer />
                        <v-toolbar-items>
                            <v-btn @click="packetsDialog = false" icon><v-icon>mdi-close</v-icon></v-btn>
                        </v-toolbar-items>
                    </v-toolbar>
                    <v-card>
                        <v-card-text>
                            <user-packets @refresh="getAccounts" :key="viewedAccount"  :account="viewedAccount" />
                        </v-card-text>
                    </v-card>
                </div>
            </v-dialog>
        </div>
        <div v-if="accounts.length === 1">
            <account-panel @modify="getAccounts" :account="accounts[0].account" />
        </div>
        <v-dialog v-model="infoDialog" persistent fullscreen>
            <div v-if="viewedAccount" >
                <v-toolbar  tile dense flat color="blue-grey darken-4">
                    <v-toolbar-title>{{viewedAccount.label}} [{{viewedAccount.login}}]</v-toolbar-title>
                    <v-spacer />
                    <v-toolbar-items>
                        <v-btn @click="infoDialog = false" icon><v-icon>mdi-close</v-icon></v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <v-card>
                    <v-card-text>
                        <account-panel :key="viewedAccount" @modify="getAccounts" :account="viewedAccount" />
                    </v-card-text>
                </v-card>
            </div>
        </v-dialog>
    </div>
</template>

<script>
    import UserService from "../../service/api/userService";
    import UserPackets from "../packet/UserPackets";
    import AccountInfo from "./AccountInfo";
    import AccountService from "../../service/api/accountService";
    import User from "../../views/User";
    import AccountHistory from "./AccountHistory";
    import ConfigInstruction from "./ConfigInstruction";
    import AccountPanel from "./AccountPanel";
    import PacketListExtended from "../packet/PacketListExtended";

    export default {
        name: "account-table",
        components: {PacketListExtended, AccountPanel, ConfigInstruction, AccountHistory, User, AccountInfo, UserPackets},
        props: {
          type : {
            type : String
          }
        },
        created() {
            this.getAccounts()
        },
      computed: {
        isCS() {
          return this.type === 'CS'
        }
      },
        data() {
            return {
                infoDialog : false,
                packetsDialog : false,
                configDialog : false,
                availableDialog : false,
                viewedAccount : null,
                accounts: [],
                panelsState : 0,
                filter : null,
                options : {},
                total : 0,
                loading : false,
                headers : [
                    {text : this.$t('base.status'), value: "enabled", sortable: false},
                    {text : 'ss', value: "subs",sortable : false},
                    {text : this.$t('packet.until'), value: "until",sortable : false},
                    {text : this.$t('base.label'), value: "label"},
                    {text : this.$t('base.login'), value: "login"},
                    {text : this.$t('base.password'), value: "password", sortable: false},
                    {text : this.$t('base.created'), value: "createDateTime" , sortable: true},
                     {text : "", value: "actions", sortable: false},

                ]
            }
        },
        watch: {
            options : {
                handler(){
                    this.getAccounts()
                },
                deep :true
            },
            filter : {
                handler(){
                    this.options.page = 1
                    this.getAccounts()
                },
            }
        },
        methods: {
            getWatchColor(acc){
                if (acc.packetState.active > 0) return "green"
                if (acc.packetState.paused > 0) return "yellow"
                if (acc.packetState.deferred > 0) return "blue"
                return "red"
            },
            openSubsDialog(acc){
                this.viewedAccount = acc
                this.packetsDialog = true
            },
            openInfoDialog(acc){
                this.viewedAccount = acc
                this.infoDialog = true
            },
            openPacketsDialog(acc){
                this.availableDialog = true
              this.viewedAccount = acc
              this.$nextTick(() => {
                this.$refs.availablePacketList.showed = true
              })
            },
            openConfigDialog(acc){
                this.configDialog = true
              this.viewedAccount = acc
              this.$nextTick(() => {
                this.$refs.deviceConfig.showed = true
              })
            },
            // createAccount(){
            //     AccountService.create().then(res => {
            //         this.$swal(this.$t('base.success'),'EE','success')
            //         this.getAccounts()
            //     })
            // },
            getAccounts() {
                this.loading = true
                AccountService.getAccounts(this.options, this.type,this.filter).then(res => {
                    this.accounts = res.data.content
                    this.total = res.data.totalElements
                    this.panelsState =  this.accounts.length === 1  ? 0 : -1
                }).finally(() => this.loading = false)
            },

        },
    }
</script>

<style scoped>

</style>