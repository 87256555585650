<template>
  <div>
    <h1 class="text-center">{{$t('messages.payment_success')}}</h1>
    <v-row class="mt-2">
      <v-img contain width="100" height="200" src="@/assets/happy.png" />
    </v-row>
  </div>
</template>

<script>
export default {
  name: "SuccessPayment"
}
</script>

<style scoped>

</style>