<template>
  <v-container fluid>
    <user-accounts type="IPTV" />
  </v-container>
</template>

<script>
    import UserAccounts from "../components/user/UserAccounts";
    export default {
        name: "Iptv",
      components: {UserAccounts}
    }
</script>

<style scoped>

</style>