<template>
    <v-main class="back">
        <v-container
                fluid
                fill-height>
            <v-layout
                    align-center
                    justify-center>
                <v-flex
                        xs12
                        sm8
                        md6>
                        <v-form v-if="!isVerified" ref="form" @submit.prevent="signIn()">
                            <v-card-text>
                              <v-row>
                                <v-col cols="12" md="6">
                                  <v-text-field
                                      solo
                                      v-model="login"
                                      :rules="requiredRule"
                                      :label="$t('base.login')"
                                      prepend-inner-icon="mdi-account"
                                      type="text"
                                  ></v-text-field>

                                  <v-text-field
                                      solo
                                      v-model="password"
                                      :rules="requiredRule"
                                      :label="$t('base.pass')"
                                      prepend-inner-icon="mdi-lock"
                                      :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                                      @click:append="showPass = !showPass"
                                      :type="showPass ? 'text' : 'password'"
                                  ></v-text-field>
                                  <div class="d-flex justify-center mb-2">
                                    <captcha
                                        ref="captchaWidget"
                                        @verify="res => captcha = res"
                                        @expired="captcha = ''"
                                    />
                                  </div>
                                  <v-btn type="submit" block :loading="loading"  x-large color="pink">
                                    {{$t('auth.signIn')}}
                                    <v-icon right>mdi-account-key</v-icon>
                                  </v-btn>
                                </v-col>
                                <v-col cols="12" md="6">
                                  <v-row no-gutters>
                                    <v-col cols="12" class="text-right">
                                      <v-btn to="/register" class="mb-1"  block large >
                                        {{$t('auth.register')}}
                                      </v-btn>
                                      <v-btn to="/forgot" large block class="mb-1">
                                        {{$t('auth.forget')}}
                                      </v-btn>
                                      <lang-switcher big/>
                                    </v-col>
                                  </v-row>
                                </v-col>
                              </v-row>
                            </v-card-text>
                        </v-form>
<!--                      VERIFIY-->
                        <v-form v-else ref="verifyForm" @submit.prevent="verify()">
                            <v-card-text>
                                <p>{{$t('auth.verify_msg')}}</p>
                                <v-text-field
                                        outlined
                                        v-model="code"
                                        :rules="requiredRule"
                                        :label="$t('base.code')"
                                        prepend-inner-icon="mdi-account"
                                        type="text"
                                ></v-text-field>
                                <v-row>
                                    <v-col cols="12">
                                        <div class="d-flex justify-center">
                                            <captcha
                                                ref="captchaWidget"
                                                @verify="res => captcha = res"
                                                @expired="captcha = ''"
                                            />
                                        </div>
                                    </v-col>
                                    <v-col cols="12" class="text-right">
                                        <v-btn type="submit" block :loading="loading"  x-large color="pink">
                                            {{$t('base.send')}}
                                            <v-icon right>mdi-account-key</v-icon>
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-btn to="/register" text block x-large color="white">
                                            {{$t('auth.register')}}
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-form>
<!--                    </v-card>-->
                </v-flex>
                 <footer />

            </v-layout>
        </v-container>
    </v-main>
</template>
<script>
    import LangSwitcher from "@/components/layout/LangSwitcher";
    import authService from "@/service/api/authService";
    import Footer from "@/components/layout/Footer";
    import Captcha from "@/components/catcha/captcha";

    export default {
        name: "Login",
        components: {Captcha, Footer, LangSwitcher},
        created() {},
        data() {
            return {
                captcha : '',
                login: "",
                password : "",
                showPass : false,
                loading : false,
                isVerified : false,
                code : '',
                requiredRule : [
                    v => !!v || this.$t('errors.empty')
                ],
            }
        },
        methods: {
            signIn() {
                if (!this.validate()) return
                this.loading = true
                authService.signIn(this.login, this.password,this.captcha)
                    .then((res) => {
                        this.$router.push('/d')
                    })
                    .catch((error) => {
                        const msg = error.response.data.error
                        if (msg === 'User account is locked') this.isVerified = true
                        else this.$swal(this.$t('errors.error'), this.$t('errors.auth'), 'error')
                    })
                    .finally(() => {
                        this.loading = false
                        this.$refs.captchaWidget.reset()
                        this.captcha = ''
                    })
            },
            validate(){
                if (!this.$refs.captchaWidget.valid()) {
                    this.$swal(this.$t('errors.error'), this.$t('errors.captcha'), 'error')
                    return false
                }
                return this.$refs.form.validate()
            },
            verify(){
                if (!this.$refs.captchaWidget.valid()) {
                    this.$swal(this.$t('errors.error'), this.$t('errors.captcha'), 'error')
                    return false
                }
                if (!this.$refs.verifyForm.validate()) return

                authService.verify(this.code,this.captcha).then((res) => {
                    this.$router.push('/d')
                })
                .catch((error) => {
                    const msg = error.response.data.code
                    this.$swal(this.$t('errors.error'), this.$t(`errors.${msg}`), 'error')
                })
                .finally(() => {
                    this.loading = false
                    this.$refs.captchaWidget.reset()
                    this.captcha = ''
                })
            }
        },
    }
</script>

<style scoped>
    .back {
      background-image: linear-gradient(-225deg, #231557 0%, #44107A 29%, #FF1361 100%);
    }
</style>