import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
//import { preset } from 'vue-cli-plugin-vuetify-preset-reply/preset'
//import '@mdi/font/css/materialdesignicons.css'
///import '@/scss/variables.scss'

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
      //preset,
      dark : true,
      settings: {
        customProperties: true,
      },
    themes: {
      dark: {
        primary: '#ff8848',
        secondary: '#290d2a',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107'
      },
    },
  },
  icons: {
    iconfont: 'mdi',
  },
});
