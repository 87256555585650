<template>
  <div>
  <v-toolbar light dense color="yellow darken-4">
    <v-toolbar-items>
      <v-icon>mdi-help</v-icon>
    </v-toolbar-items>
    <v-toolbar-title>Faq</v-toolbar-title>
    <v-toolbar-items>
    </v-toolbar-items>
  </v-toolbar>
  <v-expansion-panels class="mt-2">
      <v-expansion-panel v-for="f in $t('faq')">
          <v-expansion-panel-header class="text-uppercase orange--text font-weight-bold">{{f.title}}</v-expansion-panel-header>
          <v-expansion-panel-content>
            <div  v-html="f.text" />
          </v-expansion-panel-content>
      </v-expansion-panel>
  </v-expansion-panels>
  </div>
</template>

<script>
    export default {
        name: "Faq"
    }
</script>

<style scoped>

</style>