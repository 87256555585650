import {networker} from "../http";
const REGISTER_URL =`${process.env.VUE_APP_API_BASE}/api/register`

const UserService = {
    changePassword(oldPassword,newPassword){
        const data = new FormData()
        data.append('current',oldPassword)
        data.append('next',newPassword)
        return networker.post(`user/pass`,data)
    },
    register(user,code){
        return networker.post(`${REGISTER_URL}/${code}`, user)
    },
    getBalance(){
        return networker.get(`user/balance`)
    },
    getUser() {
        return networker.get(`user`)
    },
    // getUserById(userId) {
    //     return networker.get(`user/${userId}`)
    // },
    getLog(options,filters){
        const desc = options.sortDesc == "true" ? "desc" : "asc"
        const sort = `${options.sortBy},${desc}`
        return networker.get(`user/log`, {
            params: {
                page: options.page,
                size: options.itemsPerPage,
                sort: sort,
                word : filters.word,
                start: filters.from,
                finish: filters.to
            }
        })
    },
    save(user){
        return networker.post('user',user)
    },
    getTransactionHistory(options,filters){
        const desc = options.sortDesc == "true" ? "desc" : "asc"
        const sort = `${options.sortBy},${desc}`
        return networker.get(`user/transaction`, {
            params: {
                page : options.page,
                size : options.itemsPerPage,
                word : filters.word,
                sort : sort,
                start : filters.from,
                finish : filters.to
            }
        })
    },
    getSubscriptionsStat(){
        return networker.get(`user/stat`)
    }

}

export default UserService