<template>
    <v-content class="back">
        <v-container
                fluid
                fill-height
        >
            <v-layout
                    align-center
                    justify-center
            >
                <v-flex
                        xs12
                        sm8
                        md4
                >
                    <div>
                        <v-row no-gutters class="pr-4">
                            <v-spacer />
                            <lang-switcher></lang-switcher>
                        </v-row>
                        <v-form ref="form" @submit.prevent="register()">
                            <v-card-text>
                                <v-container grid-list-md>
                                    <v-layout row wrap>
                                        <v-flex xs12>
                                            <v-text-field
                                                    solo
                                                    v-model="user.login"
                                                    :rules="loginRule"
                                                    :label="$t('base.login')"
                                                    prepend-inner-icon="mdi-account"
                                                    type="text"
                                            ></v-text-field>
                                        </v-flex>
                                        <v-flex xs12>
                                            <v-text-field
                                                    solo
                                                    v-model="user.email"
                                                    :rules="emailRule"
                                                    :label="$t('base.email')"
                                                    prepend-inner-icon="mdi-email"
                                                    type="text"
                                            ></v-text-field>
                                        </v-flex>
                                        <v-flex xs12 sm6>
                                            <v-text-field
                                                    solo
                                                    v-model="user.password"
                                                    :rules="password_rule"
                                                    :label="$t('base.password')"
                                                    prepend-inner-icon="mdi-lock"
                                                    :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                                                    @click:append="showPass = !showPass"
                                                    :type="showPass ? 'text' : 'password'"
                                            ></v-text-field>
                                        </v-flex>
                                        <v-flex xs12 sm6>
                                            <v-text-field
                                                    solo
                                                    v-model="password_confirm"
                                                    :rules="password_confirm_rule"
                                                    :label="$t('base.password_confirm')"
                                                    prepend-inner-icon="mdi-lock"
                                                    :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                                                    @click:append="showPass = !showPass"
                                                    :type="showPass ? 'text' : 'password'"
                                            ></v-text-field>
                                        </v-flex>
                                    </v-layout>
                                </v-container>
                                <v-row>
                                    <v-col cols="12" class="align-content-center">
                                        <div class="d-flex justify-center">
                                          <captcha
                                              ref="captchaWidget"
                                              @verify="onVerify"
                                              @expired="captcha = ''"
                                          />
                                        </div>
                                    </v-col>
                                    <v-col cols="12"  >
                                        <div>
                                            <v-btn type="submit" block :loading="loading" x-large color="pink">
                                                {{$t('auth.register')}}
                                                <v-icon right>mdi-account-plus</v-icon>
                                            </v-btn>
                                        </div>
                                        <div>
                                            <v-btn color="#fff"  block text x-large to="/login">{{$t('auth.signIn')}}</v-btn>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-form>
                    </div>
                </v-flex>
            </v-layout>
        </v-container>
    </v-content>
</template>

<script>
    import LangSwitcher from "@/components/layout/LangSwitcher";
    import userService from "../../service/api/userService";
    import Captcha from "../../components/catcha/captcha.vue";

    const mediumRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))");

    export default {
        name: "Login",
        components: {Captcha, LangSwitcher},
        created() {
        },
        computed: {},
        data() {
            return {
                showPass : false,
                captcha : "",
                user : {
                    login: "",
                    password: "",
                    email : "",
                },
                password_confirm : "",
                loading : false,
                loginRule : [
                    v => !!v || this.$t('errors.empty'),
                    v => (v.length > 5  && v.length < 15) || this.$t('errors.size', { min : 6 , max : 15}),
                    v => /^[A-Za-z0-9_-]{6,15}$/.test(v) || this.$t('errors.login_regexp')
                ],
                emailRule : [
                    v => !!v || this.$t('errors.empty'),
                    v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || this.$t('errors.email')
                ],
                requiredRule : [
                    v => !!v || this.$t('errors.empty'),
                ],
                password_rule : [
                    v => !!v || this.$t('errors.empty'),
                    v => mediumRegex.test(v) || this.$t('errors.password_strong'),
                    v => v.length >= 6 || this.$t('errors.length', {length : 6})
                ],
                password_confirm_rule : [
                    v => !!v || this.$t('errors.empty'),
                    v => v === this.user.password || this.$t('errors.pass_confirm')
                ]
            }
        },
        methods: {
            register(){
                if (!this.validate()) return
                this.loading = true
                userService.register(this.user,this.captcha)
                    .then((res) => {
                        this.$swal(this.$t('messages.success'),this.$t('messages.register'),"success")
                        this.$router.push("/login")
                    })
                    .catch((error) => {
                        const code = error.response.data.code
                       // if (code === "DUPLICATE_ERROR") {
                            const msg = error.response.data.error
                            this.$swal(this.$t(`errors.${code}`), msg, "error")
                        // } else
                        //     this.$swal(this.$t(`errors.error`), this.$t(`errors.def`), "error")

                    })
                    .finally(() => {
                        this.loading = false
                        this.$refs.captchaWidget.reset()
                        this.captcha = ''
                    })
            },
            validate(){
                if (!this.$refs.captchaWidget.valid()) {
                    this.$swal(this.$t('errors.error'), this.$t('errors.captcha'), 'error')
                    return false
                }
                return this.$refs.form.validate()
            },
            onVerify(r){
                this.captcha = r
            }
        },
    }
</script>

<style scoped>
    .back {
      background-image: linear-gradient(-225deg, #231557 0%, #44107A 29%, #FF1361 100%);

    }
</style>