<template>
  <v-main class="back">
    <v-container
        fluid
        fill-height
    >
      <v-layout
          align-center
          justify-center
      >
        <v-flex
            xs12
            sm8
            md4>
          <div>
            <v-row no-gutters class="pr-4">
              <v-spacer/>
              <lang-switcher></lang-switcher>
            </v-row>
            <v-form ref="form" @submit.prevent="recover()">
              <v-card-text>
                <v-text-field
                    solo
                    v-model="password"
                    :rules="password_rule"
                    :label="$t('auth.newpass')"
                    prepend-inner-icon="mdi-lock"
                    type="text"
                ></v-text-field>
                <v-text-field
                    solo
                    v-model="password2"
                    :rules="password_confirm_rule"
                    :label="$t('base.password_confirm')"
                    prepend-inner-icon="mdi-lock"
                    type="text"
                ></v-text-field>
                <v-row>
                  <v-col cols="12">
                    <div class="d-flex justify-center">
                      <captcha
                          ref="captchaWidget"
                          @verify="r => captcha = r"
                          @expired="captcha = ''"
                      />
                    </div>
                  </v-col>
                  <v-col cols="12" class="text-right">
                    <v-btn type="submit" block :loading="loading" x-large color="pink">
                      {{ $t('auth.recover') }}
                      <v-icon right>mdi-account-key</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-form>
          </div>
        </v-flex>
      </v-layout>
      <footer/>
    </v-container>
  </v-main>
</template>
<script>
import LangSwitcher from "@/components/layout/LangSwitcher";
import authService from "@/service/api/authService";
import Footer from "@/components/layout/Footer";
import Captcha from "../../components/catcha/captcha.vue";

const mediumRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))");

export default {
  name: "Revover",
  components: {Captcha, Footer, LangSwitcher},
  created() {

  },
  computed: {
  },
  data() {
    return {
      captcha: '',
      password: "",
      password2: '',
      token: this.$route.query.t,
      loading: false,
      requiredRule: [
        v => !!v || this.$t('errors.empty'),
      ],
      password_rule: [
        v => !!v || this.$t('errors.empty'),
        v => mediumRegex.test(v) || this.$t('errors.password_strong'),
        v => v.length >= 6 || this.$t('errors.length', {length: 6})
      ],
      password_confirm_rule: [
        v => !!v || this.$t('errors.empty'),
        v => v === this.password || this.$t('errors.pass_confirm')
      ]
    }
  },
  methods: {
    recover() {
      if (!this.validate()) return
      this.loading = true
      authService.recover(this.token, this.password, this.captcha)
          .then((res) => {
            this.$swal(this.$t('base.success'), this.$t('messages.recover_success'), 'success')
            this.$router.push('/login')
          })
          .catch((error) => {
            this.$swal(this.$t('errors.error'), this.$t('errors.' + error.response.data.code), 'error')
          })
          .finally(() => {
            this.loading = false
            this.$refs.captchaWidget.reset()
            this.captcha = ''
          })
    },
    validate() {
      if (!this.$refs.captchaWidget.valid()) {
        this.$swal(this.$t('errors.error'), this.$t('errors.captcha'), 'error')
        return false
      }
      return this.$refs.form.validate()
    }
  },
}
</script>

<style scoped>
.back {
  background-image: linear-gradient(-225deg, #231557 0%, #44107A 29%, #FF1361 100%);
}
</style>