<template>
  <div>
    <div>
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  name: "Payment"
}
</script>

<style scoped>

</style>