import jwt_decode from "jwt-decode";
import Store from './../store'


function getJwt(){
    return Store.getters.token
}

const jwt = {
    getAll(){
      return jwt_decode(getJwt())
    },
    getUserId(){
        return jwt_decode(getJwt()).uid
    },
    getLogin(){
        return jwt_decode(getJwt()).sub
    },
    isExpired(){
        return jwt_decode(getJwt()).exp
    }
}

export default jwt