<template>
  <div>
    <textarea id="clipboard-area" class="d-none"></textarea>
<!--    <v-banner shaped color="red darken-3" single-line>{{$t('base.parent_code')}} - <strong>9381</strong></v-banner>-->
<!--    <v-btn-toggle  v-model="st" tile color="orange accent-3" class="my-1 flex-wrap" group mandatory>-->
<!--      <v-btn small value="all">#ALL</v-btn>-->
<!--      <v-btn small v-for="t in tags" :value="t">#{{t}}</v-btn>-->
<!--    </v-btn-toggle>-->
    <v-row>
      <v-col cols="12" md="4" v-for="p in list">
        <v-card
            class="mx-auto"
            light
        >
          <v-card-title>
            {{p.name}} <v-spacer/>
<!--            <v-chip small dark v-if="p.name.toLowerCase() === 'siptv'">pin: 9381</v-chip>-->
            <v-btn x-small class="ml-1" v-if="p.soft" target="_blank"  :href="p.soft">{{$t('base.download')}}</v-btn>
          </v-card-title>
          <v-card-text>
            <v-row dense justify="center" align-content="center" align="center">
              <v-img width="80" height="80" contain :src="p.image" />
              <v-col cols="12" class="text-center">
                <v-menu offset-y v-if="!p.variants || p.variants.length === 0">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn small dark rounded color="pink"
                           v-bind="attrs"
                           v-on="on" >Playlist <v-icon right>mdi-chevron-down</v-icon></v-btn>
                  </template>
                  <v-list dense>
                    <v-list-item link @click="() => {overlay.text = p.link ; overlay.show = true}">
                      <v-list-item-title>{{$t('iptv.show_link')}}</v-list-item-title>
                      <v-list-item-icon><v-icon small>mdi-eye</v-icon></v-list-item-icon>
                    </v-list-item>
                    <v-list-item link :href="p.link">
                      <v-list-item-title>{{$t('iptv.download_file')}}</v-list-item-title>
                      <v-list-item-icon><v-icon small>mdi-download</v-icon></v-list-item-icon>
                    </v-list-item>
                    <v-list-item ripple link @click="copyToClipboard(p.link)">
                      <v-list-item-title>{{$t('iptv.copy_link')}}</v-list-item-title>
                      <v-list-item-icon><v-icon small>mdi-content-copy</v-icon></v-list-item-icon>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <div v-else>
                  <v-menu offset-y v-for="v in p.variants" >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn small dark rounded color="pink"
                             class="ml-1"
                             v-bind="attrs"
                             v-on="on" >{{v.name}} <v-icon right>mdi-chevron-down</v-icon></v-btn>
                    </template>
                    <v-list dense>
                      <v-list-item ripple link @click="() => {overlay.text = v.link ; overlay.show = true}">
                        <v-list-item-title>{{$t('iptv.show_link')}}</v-list-item-title>
                        <v-list-item-icon><v-icon small>mdi-eye</v-icon></v-list-item-icon>
                      </v-list-item>
                      <v-list-item link :href="v.link">
                        <v-list-item-title>{{$t('iptv.download_file')}}</v-list-item-title>
                        <v-list-item-icon><v-icon small>mdi-download</v-icon></v-list-item-icon>
                      </v-list-item>
                      <v-list-item ripple link @click="copyToClipboard(v.link)">
                        <v-list-item-title>{{$t('iptv.copy_link')}}</v-list-item-title>
                        <v-list-item-icon><v-icon small>mdi-content-copy</v-icon></v-list-item-icon>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
              </v-col>
              <v-col cols="12" align-self="right" class="justify-center text-center">
                <div class="black"><v-chip class="ma-1 font-weight-bold" x-small  color="orange"
                             v-for="t in p.tags">{{t}}</v-chip>
                </div>
                <!--                <div class="d-flex justify-end">-->
<!--                  <v-img width="50" height="50" contain :src="p.image" />-->
<!--                  <div v-if="p.name.toLowerCase() === 'siptv'" class="font-weight-bold">PIN : 9381</div>-->
<!--                </div>-->
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-overlay opacity="1" :value="overlay.show">
        <v-row justify="center" class="" >
          <div class="text-center pa-6">
            <div class="text-lg-h3 text-md-h5 text-sm-h5" style="overflow-wrap: anywhere;">{{overlay.text}}</div>
            <v-btn class="mt-2" outlined @click="overlay.show = false">
            <v-icon left>mdi-close</v-icon> {{$t('base.close')}}
            </v-btn>
          </div>
        </v-row>
    </v-overlay>
    <v-snackbar v-model="snack">{{$t('messages.copied')}}</v-snackbar>
  </div>
</template>

<script>
import iptvService from "../../service/api/iptvService";

export default {
  name: "Playlists",
  props: {
    id: {
      type: String,
    },
  },
  computed: {
    list() {
      const st = this.st
      if (!st || st === 'all') return this.pl
      return this.pl.filter(p => p.tags.includes(st))
    }
  },
  data() {
    return {
      overlay : {
        text : "",
        show : false,
      },
      st : '',
      pl: [],
      snack : false,
      tags : [],
    }
  },
  created() {
    this.getTags()
    this.getPlaylists(this.id)
  },
  methods: {
    getTags(){
      iptvService.getTags().then(res => this.tags = res.data)
    },
    getPlaylists() {
      iptvService.getPlaylists(this.id).then(res => this.pl = res.data)
    },
    copyToClipboard(str){
      if (navigator.clipboard) {
        navigator.clipboard.writeText(str).then(() => {
          this.snack = true
        })
        return
      }
      // const body = document.querySelector('body');
      // const area = document.createElement('textarea');
      // body.appendChild(area);
      //
      // area.value = str;
      // area.select();
      // document.execCommand('copy');
      //
      // body.removeChild(area);
    }
  },
}
</script>

<style scoped>

</style>