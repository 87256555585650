<template>
  <v-row justify="center" align="center">
    <v-progress-circular v-if="loading"  indeterminate color="orange" size="100" />
    <div v-else class="text-center">
      <h2 class="orange--text">{{message}}</h2>
<!--      <v-btn outlined large>{{$t('auth.signIn')}}</v-btn>-->
    </div>
  </v-row>
</template>

<script>
import authService from "@/service/api/authService";
export default {
  name: "Confirm",
  components:{
  },
  computed: {},
  created() {
    this.verify()
  },
  data() {
    return {
      loading: true,
      captcha : '',
      message : '',
    }
  },
  methods: {
    onVerify(r){
      this.captcha = r
    },
    verify() {
      this.loading = true
       const code = this.$route.query.t
       authService.verify(code)
           .then(res => {
             this.$router.push('/d')
           })
           .catch(err => this.message = this.$t(`errors.${err.response.data.code}`))
          .finally(() => this.loading = false)
    }
  },
}
</script>

<style scoped>

</style>