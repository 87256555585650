<template>
    <div>
        <v-toolbar dense color="yellow darken-4" light>
            <v-toolbar-items>
              <v-icon>mdi-newspaper</v-icon>
            </v-toolbar-items>
            <v-toolbar-title>News</v-toolbar-title>
          <v-spacer />
            <v-toolbar-items>
                <v-btn small  text   @click="options.page++" v-if="showBack"> <- {{$t('base.prev')}}</v-btn>
                <v-btn  small text   @click="options.page--" v-if="showForward">{{$t('base.later')}} -></v-btn>
            </v-toolbar-items>
        </v-toolbar>
        <v-data-iterator
                :options.sync="options"
                :server-items-length="total"
                hide-default-footer
                :items="items">
                        <template v-slot:default="props">
                            <v-card color="grey darken-4" v-for="item in items" class="elevation-14 mt-2 overflow-hidden" >
                                <v-card-title>{{item.title}} &nbsp;
                                    <span class="subtitle-1 yellow--text">{{item.updated}}</span> &nbsp;
<!--                                    <v-btn @click="watchFull(item)" small outlined color="orange">{{$t('help.read_all')}}</v-btn>-->
                                </v-card-title>
                              <v-divider />
                              <v-card-text>
                                    <div v-html="item.message" class="overflow-hidden"></div>
                                </v-card-text>
                            </v-card>
                        </template>
        </v-data-iterator>
        <v-dialog v-model="viewDialog" persistent max-width="400" overlay-opacity="0.9">
            <div v-if="fullViewedNews">
                <v-toolbar dense flat tile>
                    <v-toolbar-title>{{fullViewedNews.title}}</v-toolbar-title>
                    <v-spacer />
                    <v-toolbar-items>
                        <v-btn @click="viewDialog = false" icon><v-icon>mdi-close</v-icon></v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <v-card>
                    <v-card-text >
                        <v-card-text>
                            <div v-html="fullViewedNews.message"></div>
                        </v-card-text>
                    </v-card-text>
                </v-card>
            </div>

        </v-dialog>
    </div>
</template>

<script>
    import contentService from "../../service/api/contentService";

    export default {
        name: "NewsList",
        data() {
            return {
                viewDialog : false,
                items: [],
                total : 0,
                options : {itemsPerPage : 1},
                fullViewedNews : null,
            }
        },
        created() {
            this.getDataFromApi()
        },
        computed: {
            showBack() {
                return this.options.page < this.total / 2
            },
            showForward() {
                return this.options.page > 1

            }
        },
        watch: {
            options : {
                handler() {
                    this.getDataFromApi()
                },
                deep: true
            }
        },
        methods: {
            watchFull(news){
                this.viewDialog = true
                this.fullViewedNews = news
            },
            getDataFromApi() {
                contentService.getNews(this.options).then(res => {
                    this.items = res.data.content
                    this.total = res.data.totalElements
                })
            }
        },
    }
</script>

<style scoped>

</style>