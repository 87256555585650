<template>
    <v-footer app padless absolute color="black">
      <v-col class="text-right">
        <h5 class="orange--text text-center">
          <v-icon>mdi-email</v-icon>
          <a href="mailto:support@wcast.net">support@wcast.net</a>
        </h5>
        <h5 class="orange--text text-center">
          <v-icon>mdi-telegram</v-icon>
          <a target="_blank" href="https://telegram.me/WCastChat">Telegram Support</a>
        </h5>
        <div class="orange--text text-center caption">{{ver}}</div>
      </v-col>
<!--      <v-col>-->
<!--        <a href="https://freekassa.ru" target="_blank" rel="noopener noreferrer">-->
<!--          <img src="https://cdn.freekassa.ru/banners/small-red-1.png" title="Прием платежей">-->
<!--        </a>-->
<!--      </v-col>-->
    </v-footer>
</template>

<script>
    import {version} from "../../../package.json";

    export default {
        name: "Footer",
        data() {
            return {
                ver: version
            }
        },
    }
</script>

<style scoped>
 .footer-back {
   background: #c94b4b;
   background: -webkit-gradient(linear, left top, right top, from(#2c162d), to(#c14d4d));
   background: linear-gradient(to left, #2c162d, #c14d4d);
 }
</style>