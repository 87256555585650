<template>
  <div>
    <v-dialog fullscreen v-model="show" persistent>
      <v-toolbar dense>
        <v-toolbar-title>Edit Playlist</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn @click="close"><v-icon>close</v-icon></v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card>
        <v-card-text>
          <div v-if="loading">LOAD</div>
          <v-row>
            <v-col v-for="(l,i) in channels" cols="12" md="4">
              <v-card light height="300" flat class="overflow-auto">
                <v-card-title>{{i}}</v-card-title>
                <v-card-text>
                  <v-list dense>
                    <v-list-item v-for="ch in l">
                      <v-list-item-content>{{ch.name}}</v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-col>

          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import iptvService from "@/service/api/iptvService";

export default {
  name: "PlaylistEditDialog",
  data() {
    return {
      show: false,
      loading : false,
      channels : [],
    }
  },
  props: {
    accountID: {
      type: String,
    },
  },
  created() {
    this.loadData()
  },
  methods: {
    open(){
      this.show = true
    },
    close() {
      this.show = false
    },
    loadData(){
      this.loading = true
      iptvService.getChannels(this.accountID).then(res => this.channels = res.data)
          .finally(() => this.loading = false)
    }
  },
}
</script>

<style scoped>

</style>