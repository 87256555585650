import * as JWT from 'jwt-decode'
import store from "../store";


export default {
    getLogin(){
        const token = store.getters.token
        const data = JWT(token);
        return data.sub
    },
    isExpired(){
        const token = store.getters.token
        const data = JWT(token);
        return  data.exp
    }
}