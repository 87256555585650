<template>
    <v-container>
        <user-log :handler="handler"></user-log>
    </v-container>
</template>

<script>
    import UserLog from "../components/user/UserLog";
    import UserService from "../service/api/userService";
    export default {
        name: "HistoryLog",
        components: {UserLog},
        data() {
            return {
                user: null,
            }
        },
        computed: {
            handler() {
                return (pagination,filters) => UserService.getLog(pagination,filters)
            }
        },
        created() {
           // this.getUser()
        },
        methods: {
        },
    }
</script>

<style scoped>

</style>