import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import i18n from './i18n'
import  './plugins/dependts'
import './plugins/filters'
import authService from "./service/api/authService";

Vue.config.productionTip = false

store.commit('initToken');

router.beforeEach((to, from, next) => {
  const publicPages = ['Login','Register','Welcome','ForgotPassword','Recover','Confirm'];
  const authRequired = !publicPages.includes(to.name);
  const loggedIn = authService.isAuth()

  if (authRequired && !loggedIn)
    return next('/');

  if (to.name === "Login" && loggedIn){
    return next('/d')
  }

  return next();
})

new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
