import {networker} from "../http";

const AccountService = {
    getAccounts(options,provider,filter){
        const sort = options.sortBy && options.sortBy.length > 0 ? options.sortBy[0] : "createDateTime"
        const dir = options.sortDesc && options.sortDesc.length > 0  ? options.sortDesc[0] ? "desc" : "asc"  : "desc"
        return networker.get(`account`, {
            params: {
                provider : provider,
                filter: filter == null ? "" : filter,
                page : options.page,
                size : options.itemsPerPage,
                sort : `${sort},${dir}`
            }
        })
    },
    create(t){
        const data = new FormData()
        data.append('p',t)
        return networker.post("account",data)
    },
    save(account){
        return networker.put("account",account)
    },
    getConfig(id){
        return networker.get(`account/${id}/config`)
    },
    getLog(id,options,filters) {
        const desc = options.sortDesc == "true" ? "desc" : "asc"
        const sort = `${options.sortBy},${desc}`
        return networker.get(`account/${id}/log`, {
            params: {
                page: options.page,
                size: options.itemsPerPage,
                sort: sort,
                word: filters.word,
                start: filters.from,
                finish: filters.to
            }
        })
    },
    remove(id){
        return networker.post(`account/${id}/hide`)
    }
}

export default AccountService