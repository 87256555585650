<template>
    <v-main>
        <v-container
                fluid
                fill-height
        >
            <v-row class="fill-height ma-0"
                   align="center"
                   justify="center">
                <v-col cols="12" md="6" class="text-center align-content-center">
                    <v-row         align="center"
                                   justify="center">

                        <v-img
                                class="align-content-center"
                                src="@/assets/logo.png"
                                contain
                                max-width="300"
                                max-height="500"
                        />
                    </v-row>
                    <v-row  align="center"
                            justify="center">
                        <lang-switcher />
                    </v-row>
                </v-col>
                <v-col cols="12" md="6">
                    <main-page-info />
                    <v-btn to="/login" color="pink"
                           :block="$vuetify.breakpoint.mobile"
                           class="mt-2" x-large shaped>
                        {{$t('content.main.cabinet')}}
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>
    </v-main>
</template>

<script>
    import LangSwitcher from "../components/layout/LangSwitcher";
    import Footer from "../components/layout/Footer";
    import MainPageInfo from "@/components/layout/MainPageInfo";
    export default {
        name: "Main",
        components: {MainPageInfo, Footer, LangSwitcher},
        data() {
            return {
            }
        },
    }
</script>

<style scoped>

</style>