import {networker} from "../http";

const serverService = {
    getServers(){
        return networker.get("server/")
    },
    getFilters(){
        return networker.get("server/filter")
    }
}

export default serverService