import {networker} from "../http";

const iptvService = {
    getPlaylists(accountId){
        return networker.get(`iptv/${accountId}/playlist`)
    },
    getTags(){
        return  networker.get(`iptv/playlist/tags`)
    },
    getChannels(accountID){
        return networker.get(`iptv/${accountID}/channels`)
    }
}

export default iptvService