<template>
  <div>
<!--    <v-btn link text v-for="item in menu" :to="item.path">-->
<!--      <v-icon left>{{item.meta.icon}}</v-icon>-->
<!--      {{$t(`menu.${item.name}`)}}-->
<!--    </v-btn>-->
    <v-menu v-if="isMobile" offset-overflow>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            outlined
            text
            dark
            v-bind="attrs"
            v-on="on"
        >
          {{$t(`menu.${$route.name}`)}} <v-icon>mdi-arrow-down</v-icon>
        </v-btn>
      </template>
      <v-list >
        <v-list-item :class="{'yellow--text' : item.meta.colored}" v-for="item in menu" v-if="!item.meta.hide" link :to="item.path">
          <v-list-item-icon><v-icon :color="item.meta.colored ? 'yellow' : ''">{{item.meta.icon}}</v-icon></v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{$t(`menu.${item.name}`)}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item   link @click="logout">
          <v-list-item-icon><v-icon>mdi-logout</v-icon></v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{$t(`menu.Logout`)}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-tabs v-else  background-color="transparent" >
      <v-tab v-for="item in menu" :class="{'yellow--text' : item.meta.colored}"
             v-if="!item.meta.hide" link :to="item.path" class="white--text">
        <v-icon left :color="item.meta.colored ? 'yellow' : 'white'">{{item.meta.icon}}</v-icon>
        {{$t(`menu.${item.name}`)}}
      </v-tab>
      <v-tab class="white--text" @click="logout">{{$t('menu.Logout')}}
        <v-icon left color="white">mdi-exit</v-icon>
      </v-tab>
    </v-tabs>
  </div>
</template>

<script>
import {menu} from './../../router/routes'
import authService from "@/service/api/authService";

export default {
  name: "HMenu",
  data() {
    return {
      menu: menu
    }
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    }
  },
  methods: {
    logout() {
      authService.logout()
      this.$router.push("/login")
    }
  },
}
</script>

<style scoped>

</style>