<template>
  <div>
    <v-sheet color="white">
      <div class="d-flex w-cont flex-wrap justify-space-around">
        <div  class="widget text-center" @click="send()">
          <v-img contain :src="`https://widget.donatepay.ru/images/pay/icons/cardeur.svg`" height="50" width="50"/>
          <span class="black--text text-caption font-weight-bold">CARD</span>
        </div>
      </div>
      <div class=" text-center red--text font-weight-bold">{{$t('messages.dont_change')}}</div>
    </v-sheet>
  </div>
</template>

<script>
import jwt from "@/plugins/jwt";
export default {
  name: "DonattyPayment",
  props: {
    mdata: {
      type: Object
    },
    amount : {
      type : Number
    },
    valid : {
      type : Boolean,
    }
  },
  methods: {
    send() {
      if (!this.valid) {
        return
      }
      const uid = jwt.getUserId()
      const st=`${this.mdata.handler}/${this.mdata.id}/${uid}/${this.amount}`
      location.href=st
    }
  },
}
</script>

<style scoped>
.widget {
  padding: 10px;
}
.widget:hover {
  background-color: pink;
  cursor: pointer;
}
</style>