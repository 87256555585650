export default {
    messages : {
        dont_change : "Пожалуйста не меняйте поля формы оплаты или мы не сможем идентифицировать платеж.",
      success : "Отлично",
      register : "Регистрация прошла успешно! Мы отправили вам код для подтверждения регистрации",
      forgot_success : "Ссылка для восстановления пароля была выслана на указанный почтовый ящик",
        copied: "Скопировано в буфер обмена",
        payment_error : 'Оплата не удалась',
        payment_success: 'Оплата прошла успешно',
        alert : 'Внимание! Если у вас есть проблемы с просмотром iptv, обновите ссылку на плейлист',
        recover_success : "Поздравляем! Пароль изменен"
    },
    faq : [
        // {
        //     title : 'Как получить Тест?',
        //     text : 'Нажав в Меню на отдел "Подписки", выберите клиента и нажмите рядом под пакетами на карзинку. Далее нажмите на "Купить или продлить пакет". Выберите Вас интересующий пакет и нажмите на нём кнопку "Тест", после чего Вам будет предоставлен бесплатный тест на 24 часа.'
        // },
        {
            title : 'Как пополнить Баланс?',
            text : `На странице <a href="/balance">"Пополнить баланс"</a> есть несколько способов оплаты. Вы можете выбрать наиболее удобный для Вас способ.`
        },
        {
            title : 'Как создать дополнительный аккаунт?',
            text : `При регистрации автоматически создается один аккаунт для <strong style="color : orange">шары</strong> и один для <strong style="color : orange">IPTV</strong>.</br></br> 
            Если вам нужен еще аккаунт для другого человека(клиента, родственника ...) то вверху страницы нажмите на кнопку <strong style="color: red"> Создать еще один аккаунт</strong>`
        },
        {
            title : 'В течении какого времени происходит активация пакета?',
            text : `Для шары - <strong style="color: red">5 минут.</strong> Для iptv - <strong style="color: red">10 минут.</strong> </br> </br> 
                    Также при смене сервера необходимо подождать <strong style="color: red">5 минут.</strong>`
        },
        {
            title : 'Где найти настройки для ресивера? Где найти настройки для Эмуляторов NewCamd/MgCamd/Cccam/Oscam// Wicardd ... ?',
            text : `Нажмите на кнопку <strong style="color: red">Настройка устройств</strong>. Она расположена рядом с активными подписками`
        },
        {
            title : 'Купленный пакет не работает',
            text : 'Не подключайте ресивер зарание, подождите несколько минут и только потом настраивайте или включайте ресивер. Свяжитесь с тех поддержкой, если у вас возникнут проблемы.'
        },
        {
            title : 'Можно ли смотреть с одного аккаунта на двух ресиверах?',
            text : 'Да, при покупке пакета Вы можете выбрать нужное количество ресиверов и смотреть с одного домашнего аккаунта.'
        },
        // {
        //     title : ' Как продлить пакет?',
        //     text : 'Продление пакета происходит также как покупка пакета. При продлении того же пакета неиспользованное время суммируется.'
        // },
        // {
        //     title : 'Как поставить пакет на Паузу?',
        //     text : 'Нажав в Меню на отдел "Подписки", нажмите рядом под пакетами на карзинку желаемого Аккаунта. Выберете пакет, который Вы хотите поставить на паузу нажав на "Преостановить пакет" и подтвердите.\n' +
        //         'Если Вы хотите смотреть дальше, нажмите на "Восстановить просмотр".'
        // },
        // {
        //     title : 'Можно ли остановить пакет и вернуть денги на Баланс?',
        //     text : 'Нажав в Меню на отдел "Подписки", нажмите рядом под пакетами на карзинку желаемого Аккаунта. Выберете пакет, от которого Вы хотите отказаться, нажмите на "Возврат средств" и подтвердите. Сумма за неиспользованное время возвращается на баланс.'
        // },

    ],
    chat : {
        enter_message : "Введите текст сообщения"
    },
    accounts : {
        edited : "Редактирован",
        show : 'показать аккаунты',
        accounts : 'Аккаунты',
        create : "Создать аккаунт",
        create_another : "Создать аккаунт",
        create_help : "- Для клиентов, друзей, родственников вы можете создать еще аккаунты.",
        select : "выбрать аккаунт"
    },
    transaction : {
        crypto_message : ` <b>Курс будет учитыватся на момент совершения транзакции</b>`,
        crypto_notify : "Пожалуйста, после оплаты отправьте нам номер и сумму транзакции в это поле",
        crypto_notify_message : "Номер и сумма транзакции. Коментарии по необходимости",
        no_wallets: "Нет доступных способов оплаты",
        status : {
            PAY : "Покупка",
            REFUND : "Возврат средств",
            MANUAL : "Ручная транзакция"
        }
    },
    menu : {
        Home : "Главная",
        Subscriptions : "Шара",
        Dealer : "Дилер",
        Settings : "Настройки",
        Help : "Помощь",
        History : "История",
        Transactions : "Платежи",
        UserInfo : "Информация",
        UserPackets : "Пакеты",
        UserLog : "История действий",
        UserTransactions : "Платежи",
        BalancePurchase : "Пополнение баланса",
        Logout : "Выход",
        Cardshare : "Кардшаринг",
        Iptv : "IPTV",
        SuccessPayment : '',
        ErrorPayment : '',
    },
    log : {
        filter_from : "С этой даты",
        filter_to : "По эту дату",
    },
    users : {
        blocked : "Заблокирован",
        oldpassword : "Старый пароль",
        passwordnew : "Новый пароль",
        changePassword : "Сменить пароль",
        search_login: "Поиск по логину",
        auto_continue : "Автопродление на месяц",
        enableTv : "Разрешен просмотр и покупка",
        clientCount : "Кол-во клиентов",
        addTransaction : "Добавить транзакцию",
        watchToken : "Пароль для просмотра",
        watchUser : "Логин для просмотра",
        watchData : "Учетные данные для просмотра"
    },
    help : {
        price_enabled : "Вкл/Выкл покупку пакета у данной группы покупателей",
        search_packet_name : "Поиск по имени пакета",
        autoRenew : "Автопродление",
        deniedPayAndWatch : "Запрещена покупка и просмотр",
        choose_period : "Выберите период",
        choose_date_period : "Выберите дату начала и окончания подписки",
        not_valid_pay_period : "Укажите корректный период действия подписки",
        click_to_change_balance : "Кликните для изминения баланса",
        pay_success : "Пакет куплен!",
        device_info : "Настройка устройств",
        read_all : "Читать полностью",
        coming_soon : "Мы активно работаем над запуском услуги IPTV"
    },
    price : {
        balance : "Кошелек",
        amountPurchase : 'Сумма пополнения в евро',
        purchase : "Пополнить баланс",
        category : "Категория прайслист",
        daily : 'Цена за сутки',
        daily_price : '{price} - сутки',
        month_price : '{price} - месяц',
        days_free : '{days} дней бесплатно',
        payable : "Платные",
        frees : 'Бесплатные',
        total : "Итого",
        PACKET_DAYS_PRICE : "Цена за период",
        TV_FILTER_PRICE : "Цена за устройство",
        PERSONAL_DISCOUNT : "Персональная скидка",
        PERIOD_DISCOUNT : "Скидка за период",
        DEALER_DISCOUNT : "Дилерская скидка",
        instruction : "Инструкция по оплате",
        payment_via_agent : 'Посредник',
        payment_process : "Перейти к оплате",
    },
    base : {
        account : 'Аккаунт',
        label : "Название",
        enabled : "Включено",
        disabled : "Отключено",
        login : "Логин",
        pass : "Пароль",
        email : "Почта",
        password : "Пароль",
        password_confirm : "Подтверждение пароля",
        search : 'Поиск',
        success : "Отлично",
        done : "Операция прошла успешно!",
        servers : "Сервера",
        status : "Статус",
        discount : "Скидка",
        add : "Добавить",
        logo : "Логотип",
        free : "Бесплатно",
        save : "Сохранить",
        desc : "Описание",
        server : "Сервер",
        name : "Название",
        free_packet : "Бесплатный пакет",
        filter : "Фильтр",
        amount : "Стоимость",
        note : "Заметка",
        bydefault : "По умолчанию",
        dealer : "Дилер",
        created : "Создан",
        user : "Пользователь",
        clients : "Клиенты",
        info : "Информация",
        pay : "Купить",
        sure : "Вы уверены?",
        cancel : "Отмена",
        confirm : "Да",
        log : "История действий",
        transactions : "Транзакции",
        time : "Время",
        edit : "Редактировать",
        prev :"РАНЕЕ",
        later : "ПОЗЖЕ",
        send : "Отправить",
        code : "Код верификации",
        full : "Показать полностью",
        download : 'Скачать',
        copy : "Копировать в буфер",
        parent_code : 'Родительский пароль',
        close : "Закрыть",
        subs : "Подписки",
        remove : "Удалить"
    },
    auth : {
        newpass : "Новый пароль",
        signIn : "Войти",
        logout : "Выход",
        register : "Регистрация",
        forget : "Забыл пароль?",
        recover : "Отправить",
        recover_msg : "Ссылка для восстановления будет отправлена на почту",
        verify_msg : "Проверте свой email и введите код для подтверждения регистрации",
        recover_success : 'Отлично! Теперь вы можете войти в свой аккаунт',
        expired : "Ваш токен истек, введите логин и пароль чтобы продолжить",
    },
    errors : {
        error : "Ошибка",
        def : "",
        auth : "Неверный логин или пароль",
        empty : "Заполните поле",
        email : "Некорректный email",
        pass_confirm: "Пароли не совпадают",
        size : "Длина должна быть от {min} до {max}",
        VERIFY_WAIT : "Введете код верификации",
        VERIFY_INVALID : "Неверный код верификации",
        PACKET_PAY_DENIED : "Запрещено покупать и просматривать пакеты",
        PAUSE_DENIED : "Нельзя использовать приостановку так часто",
        FREE_PACKET_USER_DENIED : "Пользователю запрещена покупка бесплатных пакетов",
        NO_MONEY : "Не хватает средств для покупки",
        DATE_NOT_CORRECT : "Указан некорректный период действия подписки",
        PACKET_NOT_FREE : "Этот пакет не распространяется бесплатно",
        PACKET_ALREADY_USED : "Этот пакет уже куплен",
        number : "Введите число",
        DAYS_PERMIT : "Возврат запрещен",
        DUPLICATE_PERIOD : "У вас есть активная подписка в этот период",
        REFUND_DAYS_PERMIT : "Возврат недоступен для текущей подписки",
        WRONG_PASSWORD : "Пароль не подходт",
        captcha : "Заполните капчу",
        CAPTCHA_ERROR : "Капча не прошла проверку",
        EMAIL_NOT_FOUND : "Такая почта не найдена",
        DUPLICATE_ERROR : "Эти данные уже используются",
        password_strong : "Цифры и буквы",
        length : "Не менее {length} символов",
        LOGIN_EXIST : "Этот логин уже занят",
        EMAIL_EXIST : "Этот email уже занят",
        login_regexp : "Только латинские буквы и цифры",
        LIMIT_ERROR : 'Извините , в данный момент операция не доступна',
        maxPurchase : 'Максимум - 25 euro',
        minPurchase : "Минимум - {value} euro"
    },
    packet : {
        subs : 'Подписок',
        custom_period : "Указать даты",
        days_period : "Указать в днях",
        until: "Заканчивается",
        active_small : "Активные",
        paused : "На паузе",
        deferred : "Ожидают",
        deviceFilter : "Количество коннектов(устройств)",
        connections : 'подключений',
        free : "Пакеты для тестирования",
        free_days : "Дней бесплатно",
        groups : "Пакетная группа",
        pricelist : "Прайслист",
        ports : "Порты",
        filters : "Фильтры",
        caid : "CAID",
        ident : "IDENT",
        packets : "Пакеты",
        from : "Начало подписки",
        to : "Окончание подписки",
        users : "Подписки",
        do_pause : "Приостановить подписку",
        do_unpause : "Возобновить просмотр",
        refund : "Возврат средств",
        empty : "Нет пакетов для просмотра",
        get_free_days : "Попробывать бесплатно",
        pay : "Купить[TEST]",
        pause_time : "Поставлен на паузу",
        deffered : "Отложенный просмотр",
        archive : "В архиве",
        active : "Активные подписки",
        soon_expired : "Скоро закончится",
        packet_actions_help : `
        Подписку можно в любой момент приостановить на неопределенный срок. 
        Для того чтобы снова воспользоватся услугой должно пройти 10 дней.
        \n \n \r \r
        За оставшиеся дни на подписке можно вернуть деньги на кошелек.
        `,
        autoContinueEnabled : `Автопродление включено, подписка продлевается за {days} дней до окончания текущей подписки`,
        no_subscriptions : "У вас нет активных подписок, попробуйте тестовую подписку",
        no_subscriptions_warn : "На данный момент у вас нет активных подписок. Выберите подписки которые вы бы хотели подключить",
    },
    period : {
        day : "Дня",
        hour : "Часов",
        week : "Неделя",
        month : "Месяц",
        months : "Месяцы",
        year : "Год",
    },
    content : {
        main : {
            cabinet : "Банзай!",
            title : "TV",
            pluses1 : "Бесплатный просмотр",
            pluses2 : "Более одного устройства для просмотра",
            pluses3 : "Лояльная партнерская программа",
        }
    },
    iptv : {
        show_link : "Показать ссылку на плейлист",
        copy_link : "Копировать ссылку на плейлист в буфер обмена",
        download_file : "Скачать файл с плейлистом",
        channels : "каналов",
    }
}
