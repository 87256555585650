<template>
    <div>
        <v-container >
            <v-row class="grid-list-sm">
                <v-col cols="12" sm="4" md="3" lg="2" v-for="item in items">
                    <v-card height="80"
                            class="text-center pt-2 orange--text"
                            :to="item.path">
                        <v-icon x-large>{{item.meta.icon}}</v-icon>
                        <div class="text-center title">{{$t(`menu.${item.name}`)}}</div>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import {menu} from './../../router/routes'
    export default {
        name: "BlockMenu",
        data() {
            return {
                items: menu
            }
        },
    }
</script>

<style scoped>

</style>