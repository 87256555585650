<template>
<div>
  <h1 class="text-center">{{$t('messages.payment_error')}}</h1>
  <v-row class="mt-2">
    <v-img contain width="100" height="200" src="@/assets/sad.png" />
  </v-row>
</div>
</template>

<script>
export default {
  name: "ErrorPayment"
}
</script>

<style scoped>

</style>