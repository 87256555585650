<template>
  <div>
    <v-dialog v-model="opened" overlay-color="black" max-width="400" overlay-opacity="0.9" persistent>
      <v-card>
        <v-card-text class="pt-3">
          <p class="orange--text text-uppercase text-center font-weight-bold">{{$t('auth.expired')}}</p>
          <v-form ref="form">
            <v-text-field dense solo-inverted
                          prepend-inner-icon="mdi-account"
                          :label="$t('base.login')" v-model="login" :rules="rules" />
            <v-text-field dense solo-inverted :label="$t('base.pass')"
                          prepend-inner-icon="mdi-lock"
                          :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                          @click:append="showPass = !showPass"
                          :type="showPass ? 'text' : 'password'"
                          v-model="password" :rules="rules" />
            <captcha
                ref="captchaWidget"
                @verify="r => captcha = r"
                @expired="captcha = ''"
            />
            <v-btn class="mt-2" block :loading="loading" @click="signIn"
                   color="pink darken-3">{{$t('auth.signIn')}}</v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import authService from "@/service/api/authService";
import {EventBus,EventBusCommands} from "@/service/eventBus";
import {replayRequests} from "@/service/http";
import Captcha from "../catcha/captcha.vue";

export default {
  name: "LoginDialog",
  components : {Captcha},
  created() {
    EventBus.$on(EventBusCommands.R_TOKEN_EXPIRED, this.showDialog)
  },
  data() {
    return {
      opened: false,
      login : "",
      captcha : "",
      showPass : false,
      password : "",
      loading : false,
      rules : [
          v => !!v || this.$t('errors.empty')
      ]
    }
  },
  computed: {
  },
  methods: {
    showDialog(){
      this.opened = true
    },
    close(){
      this.login = ''
      this.password = ''
      this.captcha = ''
      this.opened = false
    },
    signIn() {
      if (!this.validate()) return
      this.loading = true
      authService.signIn(this.login,this.password,this.captcha)
      .then(res => {
        replayRequests(res.access)
        this.close()
      })
      .catch(err => {
        this.$swal(this.$t('errors.error'), this.$t('errors.auth'), 'error')
      })
      .finally(() => {
        this.$refs.captchaWidget.reset()
        this.captcha = ''
        this.loading = false
      })
    },
    validate(){
      if (!this.$refs.captchaWidget.valid()) {
        this.$swal(this.$t('errors.error'), this.$t('errors.captcha'), 'error')
        return false
      }
      return this.$refs.form.validate()
    },
  },
}
</script>

<style scoped>

</style>