<template>
  <div>
      <payment-method
        id="custom"
        :icon="require('@/assets/payment/eupay.png')"
        :title="$t('price.payment_via_agent')"
        @paymentClick="id => descriptionDialog = true"
      />
      <v-dialog fullscreen v-model="descriptionDialog">
<!--          <template v-slot:activator="{on}">-->
<!--            <v-btn color="pink darken-3" v-on="on" block>{{$t('price.instruction')}} <v-icon right>mdi-comment-question-outline</v-icon></v-btn>-->
<!--          </template>-->
          <v-toolbar dense>
            <v-toolbar-title>{{$t('price.instruction')}}</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-btn icon @click="descriptionDialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <v-card light>
            <v-card-text class="pt-5 black--text" v-html="description">
            </v-card-text>
          </v-card>
        </v-dialog>
  </div>
</template>

<script>
import PaymentMethod from "@/components/payment/PaymentMethod.vue";

export default {
  name: "CustomPayment",
  components: {PaymentMethod},
  props: {
    mdata: {
      type: Object
    },
  },
  computed: {
    description(){
      if (!this.mdata.description) return ""
      const lang = this.$store.getters.userLanguage
      return this.mdata.description[lang]
    },
    methods() {
      if (!this.mdata || !this.mdata.methods) return  []
      return  this.mdata.methods.split(",")
    }
  },
  data() {
    return {
      descriptionDialog: false
    }
  },
}
</script>

<style scoped>
.widget {
  padding: 10px;
}
.widget:hover {
}
</style>