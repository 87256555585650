<template>
    <div>
        <v-container grid-list-xs>
                <v-row row wrap>
                  <v-col cols="12" md="6">
                      <v-form ref="userForm" @submit.prevent="save()">
                        <div class="text-center title">{{$t('base.email')}}</div>
                        <v-text-field :label="$t('base.email')" :rules="[rules.required, rules.email]" outlined v-model="user.email"></v-text-field>
                        <v-btn :loading="loading" color="success" type="submit" x-large>{{$t('base.save')}} <v-icon>mdi-save</v-icon></v-btn>
                      </v-form>
                  </v-col>
                  <v-col cols="12" md="6">
                    <div class="text-center title">{{$t('users.changePassword')}}</div>
                    <v-form ref="passForm" @submit.prevent="changePassword">
                      <v-text-field outlined :label="$t('users.oldpassword')" :rules="password_rule" v-model="passwords.old"></v-text-field>
                      <v-text-field outlined :label="$t('users.passwordnew')" :rules="password_rule" v-model="passwords.current"></v-text-field>
                      <v-btn type="submit" :loading="loadingPasswordChange" color="success" block>
                        {{$t('users.changePassword')}}
                      </v-btn>
                    </v-form>
                  </v-col>
                </v-row>
        </v-container>
    </div>
</template>

<script>
    import UserService from "../../service/api/userService";
    import ServerSelect from "../chooser/ServerSelect";
    import FilterSelect from "../chooser/FilterSelect";
    const mediumRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))");

    export default {
        name: "user-form",
        components: {FilterSelect, ServerSelect},
        props: {
            userId: {
                type: String,
            },
        },
        data() {
            return {
                user : {
                    login : "",
                    email : "",
                    password : "",
                    autoContinue : false,
                    enableFreePacket : false,
                    discountPercent : 0,
                    server : null,
                    tvFilter : null,
                    priceCategory : null,
                    note : "",
                    enableTv : true,
                    watchUser : "",
                    watchToken : "",
                },
                passwords : {
                    old : "",
                    current : ""
                },
                loading : false,
                loadingPasswordChange : false,
                password_rule : [
                  v => !!v || this.$t('errors.empty'),
                  v => mediumRegex.test(v) || this.$t('errors.password_strong'),
                  v => v.length >= 6 || this.$t('errors.length', {length : 6})
                ],
                rules: {
                    required : v => !!v || this.$t('errors.empty'),
                    email : v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v)  || this.$t('errors.email'),
                }
            }
        },
        created(){
            this.getUser()
        },
        methods: {
            changePassword(){
              if (!this.$refs.passForm.validate()) return;

                if (!this.passwords.old || !this.passwords.current){
                    this.$swal('',this.$t('errors.empty'),'warning')
                    return
                }
                this.loadingPasswordChange = true
                UserService.changePassword(this.passwords.old,this.passwords.current)
                    .then(res => {
                        this.$swal(this.$t('messages.success'),'','success')
                    }).catch((err) => {
                        this.$swal(this.$t('errors.error'),this.$t(`errors.${err.response.data.code}`),'error')
                }).finally(() => this.loadingPasswordChange = false)
            },
            generateWatchToken(){
                let str           = '';
                const length       = 8
                const characters       = 'abcdefghijklmnopqrstuvwxyz0123456789';
                const charactersLength = characters.length;
                for ( let i = 0; i < length; i++ )
                    str += characters.charAt(Math.floor(Math.random() * charactersLength));
                return str
            },
            getUser() {
                UserService.getUser()
                    .then(res => this.user = res.data)
            },
            save(){
                if (!this.$refs.userForm.validate()) return
                //this.$swal("")
                this.loading = true
                UserService.save(this.user)
                    .then(res => {
                        this.$swal(this.$t('messages.success'),'','success')
                        this.$emit('onSave')
                    })
                    .catch(err => this.$swal('errors.error',this.$t('errors.unknown'),'error'))
                    .finally(() => this.loading = false)

            }
        },
    }
</script>

<style scoped>

</style>