<template>
<v-row>
  <v-col>
    <h1 :class="{'text-center' : $vuetify.breakpoint.smAndDown}" class="text-uppercase pink--text">{{$t('content.main.title')}} <span class="caption">{{ver}}</span></h1>
    <h2 :class="{'text-center' : $vuetify.breakpoint.smAndDown,'title' : $vuetify.breakpoint.smAndDown}" class="text-uppercase mt-2"><v-icon color="orange">mdi-email</v-icon>&nbsp;<a href="mailto:support@wcast.net">support@wcast.net</a></h2>
    <h2 :class="{'text-center' : $vuetify.breakpoint.smAndDown,'title' : $vuetify.breakpoint.smAndDown}" class="text-uppercase mt-2"><v-icon color="orange">mdi-telegram</v-icon>&nbsp;<a target="_blank" href="https://telegram.me/WCastChat">Telegram Support</a></h2>
  </v-col>
</v-row>
</template>

<script>
import {version} from "../../../package.json";

export default {
  name: "MainPageInfo",
  data() {
    return {
      ver: version
    }
  },
}
</script>

<style scoped>

</style>