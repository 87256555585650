<template>
    <div>
      <v-row  class="mb-3">
          <div class="mb-2 text-center subtitle-1 font-weight-bold">{{$t('help.choose_period')}}</div>
          <v-spacer />
          <v-btn v-if="showCustomPeriod" small light color="orange" :outlined="!customPeriod"
                 @click="customPeriod = !customPeriod"> {{optionName}}
              <v-icon small>mdi-calendar-clock</v-icon>
          </v-btn>
      </v-row>
      <div v-show="!customPeriod">
          <v-row class="mb-1">
              <v-btn x-small  color="orange" fab @click="increment(days - 1)"><v-icon>mdi-arrow-left-thick</v-icon></v-btn>
              <v-spacer />
              <h1 class="white--text text-center">{{days}} {{$t('period.day')}}</h1>
              <v-spacer />
              <v-btn color="orange darken-3" x-small fab @click="increment(days + 1)"><v-icon>mdi-arrow-right-thick</v-icon></v-btn>
          </v-row>
          <div>
              <v-slider
                      validate-on-blur
                      :max="max"
                      @change="updatePeriod"
                      :min="min"
                      v-model="days"
              ></v-slider>
            <div v-if="max > 30">
              <div class="text-center text-uppercase">{{$t('period.months')}}</div>
              <v-row no-gutters class="justify-center" dense>
                <v-btn class="ma-1" small
                       :color="days >= n * 30 ? 'red darken-3' : ''"
                       @click="increment(n * 30)"
                       :outlined="days < n * 30 "
                       v-for="n in (Math.floor(max / 30))">{{n}}</v-btn>
              </v-row>
            </div>
          </div>
      </div>
      <div v-show="customPeriod">
          <div class="mb-2 text-center subtitle-1 font-weight-bold">
              {{$t('help.choose_date_period')}}
          </div>
          <div>
              <date-chooser key="start" start-today v-model="start"  :label="$t('packet.from')"/>
          </div>
          <div>
              <date-chooser key="finish" start-today v-model="finish"  :label="$t('packet.to')"/>
          </div>
      </div>
    </div>
</template>

<script>
    import DateChooser from "../chooser/DateChooser";
    import moment from "moment";
    export default {
        name: "PricePacketPeriod",
        components : {DateChooser},
      props: {
        min: {
          type: Number,
          default: 1
        },
        max: {
          type: Number,
          default: 365
        },
        showCustomPeriod : {
          type : Boolean,
          default: false
        }
      },
      computed: {
        optionName() {
          return !this.customPeriod ? this.$t('packet.custom_period') : this.$t('packet.days_period')
        }
      },
        created() {
            this.days = this.min
            this.updatePeriod()
        },
        watch: {
            customPeriod(newValue, oldValue) {
               if (!newValue) this.updatePeriod()
            },
            start(n, oldValue) {
                this.$emit('period',{start : n, finish : this.finish})
            },
            finish(n, oldValue) {
                this.$emit('period',{start : this.start, finish : n})
            },
        },
        data() {
            return {
                ss : "",
                customPeriod : false,
                start : new Date().toISOString().substr(0, 10),
                finish : moment(new Date()).add(this.min,'d').format('YYYY-MM-DD'),
                days : 1,
               // max : 14,
            }
        },
        methods: {
            updatePeriod() {
                // const start = new Date().toISOString().substr(0, 10)
                // const finish = moment(start).add(this.days,'d').format('YYYY-MM-DD')
                // this.$emit('period',{start : start, finish : finish})
                // this.start = start
                // this.finish = finish
              this.$emit('period', this.days)
            },
            increment(days) {
                if (days < this.min || days > this.max) return
                this.days = days
                this.updatePeriod()
            }
        },
    }
</script>

<style scoped>

</style>