<template>
    <v-container>
        <user-accounts></user-accounts>
    </v-container>
</template>

<script>
    import UserService from "../service/api/userService";
    import UserAccounts from "../components/user/UserAccounts";
    export default {
        name: "Cardshare",
        components: {UserAccounts},
        data() {
            return {
            }
        },
        created() {
            // UserService.getUser().then(res => {
            //     this.userId = res.data.id
            // })
        },
        methods: {
        },
    }
</script>

<style scoped>

</style>