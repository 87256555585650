<script>
import SubscriptionsSimpleTable from "@/components/packet/subscription/SubscriptionsSimpleTable.vue";
import ConfigInstruction from "@/components/account/ConfigInstruction.vue";
import PlaylistsDialog from "@/components/iptv/PlaylistsDialog.vue";
import PacketListExtended from "@/components/packet/PacketListExtended.vue";
import UserPacketsList from "@/components/packet/UserPacketsList.vue";
import UserPriceList from "@/components/packet/UserPriceList.vue";
import Playlists from "@/components/iptv/Playlists.vue";
import SubscriptionWidgetMixin from "@/components/packet/subscription/SubscriptionWidgetMixin";

export default {
  name: "IptvSubscriptionsWidget",
  mixins: [SubscriptionWidgetMixin],
  components: {
    Playlists,
    UserPriceList,
    UserPacketsList,
    PacketListExtended,
    PlaylistsDialog,
    ConfigInstruction,
    SubscriptionsSimpleTable
  },
  props: {
    account: {
      type: Object
    },
  },
  data() {
    return {
    }
  },
  computed: {
  },
  created() {
    this.getArchivePackets(this.account.id)
  },
  methods: {
    refresh(){
      this.getUserPackets()
    },
    getUserPackets() {
      this.$refs.accountPackets.getUserPackets()
    },
  },
}
</script>

<template>
  <div>
    <user-packets-list ref="accountPackets" @loadDone="(result) => activeSubscriptions = result" :account-id="account.id"/>
    <template v-if="!hasSubs">
      <v-alert dense light text shaped type="warning">
        {{$t('packet.no_subscriptions_warn')}}
      </v-alert>
      <user-price-list @onBuy="refresh()" :account-id="account.id" />
    </template>
    <v-expansion-panels light tile class="my-2" v-if="archiveSubscriptions.length">
      <v-expansion-panel >
        <v-expansion-panel-header class="font-weight-bold">{{$t('packet.archive')}} ({{archiveSubscriptions.length}})</v-expansion-panel-header>
        <v-expansion-panel-content>
          <subscriptions-simple-table :subscriptions="archiveSubscriptions" />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <template v-if="hasSubs">
      <v-toolbar dense class="mb-2">
        <v-toolbar-title>PLAYLISTS</v-toolbar-title>
      </v-toolbar>
      <playlists v-if="hasSubs" :id="account.id" />
    </template>

  </div>
</template>

<style scoped>

</style>