<script>

import BaseAccount from "@/components/account/widget/BaseAccounItem.vue";

export default {
  name: "CSAccount",
  components: {BaseAccount},
  mixins : BaseAccount,
  props: {
    account: {
      type: Object,
    },
  },
  computed: {
  },
}
</script>

<template>
  <base-account :account="account" @openModal="data => $emit('openModal', data)"  @remove="data => $emit('remove', data)">
    <template v-slot>
      <v-row :justify="$vuetify.breakpoint.lg ? 'space-between' : 'center'" no-gutters v-if="account.packetState.active > 0">
        <div class="text-center">
          <span class="text-h1 green--text">{{account.packetState.active}}</span> {{$t('packet.subs')}}
        </div>
        <div>
          <div class="pa-1"><v-chip small color="orange" text-color="black">
            <v-icon left>mdi-account</v-icon>
            <span class="font-weight-bold">{{account.account.login}}</span></v-chip></div>
          <div class="pa-1"><v-chip small color="orange" text-color="black" dark>
            <v-icon left>mdi-key</v-icon><span class="font-weight-bold">{{account.account.password}}</span></v-chip></div>
        </div>
      </v-row>
    </template>
  </base-account>
</template>

<style scoped>

</style>