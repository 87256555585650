<template>
    <div>
        <v-container>
            <user-transactions></user-transactions>
        </v-container>
    </div>
</template>

<script>
    import UserTransactions from "../components/user/UserTransactions";
    import UserService from "../service/api/userService";
    export default {
        name: "Transactions",
        components: {UserTransactions},
        data() {
            return {
                user: null,
            }
        },
        created() {
            //this.getUser()
        },
        methods: {
            getUser() {
                UserService.getUser().then(res => this.user = res.data)
            }
        },
    }
</script>

<style scoped>

</style>