<template>
    <v-container>
        <Chat />
    </v-container>
</template>

<script>
    import Chat from "../components/chat/Chat";
    export default {
        name: "Help",
        components: {Chat}
    }
</script>

<style scoped>

</style>