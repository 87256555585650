<template>
  <div>
    <div class="text-center text-uppercase">{{$t('packet.deviceFilter')}}</div>
    <v-row no-gutters class="justify-center">
      <v-btn class="ma-1" small
             :color="n.connection <= selected ? 'red darken-3' : ''"
             @click="onChange(n)"
             :outlined="n.connection > selected"
             v-for="n in variants">{{n.connection}}</v-btn>
    </v-row>
  </div>
</template>

<script>
import serverService from "@/service/api/serverService";

export default {
  name: "ConnectionSelect",
  props: {
    value: {
      type: String,
    },
    max : {
      type : Number,
      default : -1
    },
    type : {
      type : String,
      default : 'CS'
    }
  },
  created() {
    this.load()
  },
  computed: {
    variants() {
      if (this.max < 1) return this.items
      return this.items.filter(f => f.connection <= this.max)
    }
  },
  data() {
    return {
      items: [],
      selected : 0,
    }
  },
  methods: {
    onChange(v){
      this.selected = v.connection
      this.$emit('input',v.id)
    },
    load() {
      serverService.getFilters().then(res => {
        this.items = res.data
        if (this.items.length > 0){ this.onChange(this.items[0])
        }
      })
    }
  },
}
</script>

<style scoped>

</style>