<template>
    <v-select
            :items="items"
            v-model="value"
            :label="label"
            @change="onChange"
            item-text="name"
            :rules="rules"
            persistent-hint
            return-object
            outlined
    ></v-select>
</template>

<script>
    import serverService from "../../service/api/serverService";

    export default {
        name: "filter-select",
        props: {
            rules : {
                type : Array,
                default: []
            },
            label : {
                type : String,
                default : 'Filter'
            },
            value : {
                type: Object,
                default: null
            },
        },
        created(){
            this.getFilters()
        },
        data() {
            return {
                items: []
            }
        },
        methods: {
            onChange(v){
              this.$emit('input',v)
            },
            getFilters() {
                serverService.getFilters().then(res => this.items = res.data)
            }
        },
    }
</script>

<style scoped>

</style>