<template>
    <div>
      <v-icon v-if="$vuetify.breakpoint.mdAndUp" small>mdi-account</v-icon> &nbsp;
      <strong v-if="$vuetify.breakpoint.mdAndUp" class="subTitle">{{getUserName()}}</strong> &nbsp;
      <v-chip link to="/transactions" color="orange" x-small text-color="black" class="font-weight-bold">
        {{balance | currencyFilter}}
      </v-chip> &nbsp;
<!--      <v-btn small :to="{name : 'BalancePurchase'}"-->
<!--             outlined-->
<!--             color="orange">-->
<!--        <span v-if="!mini">{{$t('price.purchase')}}</span>-->
<!--        <v-icon>mdi-currency-eur</v-icon>-->
<!--      </v-btn>-->
    </div>
</template>

<script>
    import jwt from './../../service/jwt'
    import authService from "../../service/api/authService";
    import userService from "../../service/api/userService";
    import {EventBus} from "../../service/eventBus";

    export default {
        name: "UserMenu",
        computed: {
            username() {
                this.getUserName()
            },
        },
        data() {
            return {
                balance: 0.00
            }
        },
        created(){
            EventBus.$on(["PAY","REFUND"], () => this.getBalance());
        },
        mounted(){
          this.getBalance()
        },
        methods: {
            getUserName(){
              const s =jwt.getLogin()
              return s.length > 10 ? s.substr(0,9) + "..." : s
            },
            logout() {
                authService.logout()
                this.$router.push('/login')
            },
            getBalance(){
                userService.getBalance().then(res => {
                    this.balance = res.data || 0.00
                })
            }
        },
    }
</script>

<style scoped>

</style>