<template>
    <div>
        <div id="chat-container">
            <v-timeline
                    align-top
                    dense
            >
                <v-timeline-item
                        hide-dot
                        small
                        v-for="(item, i) in messages"
                        :key="i"
                        :left="item.from.login !== userName"
                        :right="item.from.login === userName"
                        :id="`message${i}`"
                >
                    <v-card dark >
                        <v-card-title class="title">
                            <span v-if="item.from"></span>
                            {{item.from.login === userName ? 'YOU' : 'ADMIN'}} {{item.time}}
                        </v-card-title>
                        <v-card-text class="text--primary">
                            {{item.text}}
                        </v-card-text>
                    </v-card>
                </v-timeline-item>
            </v-timeline>
        </div>
        <v-text-field
                    v-on:keypress.enter="sendMessage" :label="$t('chat.enter_message')"
                    v-model="sendMessage">
            <template v-slot:append-outer>
                <v-btn  small color="pink" :loading="sendLoad" @click="send">{{$t('base.send')}}</v-btn>
            </template>
        </v-text-field>
    </div>
</template>

<script>
    import ChatService from "../../service/api/ChatService";
    import jwt from "../../service/jwt";

    export default {
        name: "Chat",
        data() {
            return {
                sendMessage : "",
                messages: [],
                sendLoad : false,
                messagesLoad : false,
                page : 1,
                scrollOptions : {
                    duration: 1,
                    offset: 1,
                    easing: 'linear',
                }
            }
        },
        computed: {
            userName() {
                return jwt.getLogin()
            }
        },
        created() {
            this.getMessages(this.page)
        },
        methods: {
            getMessages(page) {
                this.messagesLoad = true
                ChatService.getMessages(page).then(res => {
                    this.messages = this.messages.concat(res.data.content)
                }).finally(() => this.messagesLoad = false)
            },
            send(){
                if (!this.sendMessage) return
                this.sendLoad = true
                ChatService.send(this.sendMessage)
                    .then(res => {
                        this.sendMessage = ''
                        this.messages.push(res.data)
                        this.scrollToLast()
                       // this.$vuetify.goTo("#message1", this.scrollOptions)
                    })
                    .catch((e) => { alert('error')})
                    .finally(() => this.sendLoad = false)
            },
            scrollToLast(){
                setTimeout(() => {
                    let objDiv = document.getElementById("chat-container");
                    objDiv.scrollTop = objDiv.scrollHeight + 50;
                },200)
            },
        },
    }
</script>

<style scoped>
    #chat-container{
        box-sizing: border-box;
        height: calc(100vh - 9.3rem);
        overflow-y: auto;
    }
</style>