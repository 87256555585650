<template>
  <div>
    <payment-method
        id="crypto_"
        :icon="getIcon()"
        :title="mdata.name"
        @paymentClick="id => paymentInfoPopup = true"
    />
    <v-dialog v-model="paymentInfoPopup" max-width="450" overlay-opacity="0.9">
      <v-card light>
        <v-card-title>{{mdata.name}}
          <v-spacer />
          <v-btn icon @click="paymentInfoPopup = false"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <v-row justify="center">
            <v-col cols="6" md="4">
              <v-img :src="mdata.image" width="100" height="100" />
            </v-col>
            <v-col  cols="6" md="8" class="black--text justify-center align-content-center" v-html="$t('transaction.crypto_message')"/>
          </v-row>
          <v-banner  rounded outlined class="mt-1 text-center font-weight-bold">
            {{mdata.methods}} <v-btn @click="copyToClipboard(mdata.methods)" small icon color="orange"><v-icon small>mdi-content-copy</v-icon></v-btn>
          </v-banner>
          <div class="mt-2">
            <div class="caption text-center">{{$t('transaction.crypto_notify')}}</div>
            <v-textarea :loading="confirmationLoading" v-model="confirmationMessage" class="mt-1" dense
                        :label="$t('transaction.crypto_notify_message')" outlined append-icon="mdi-send"
                        @click:append="sendPayment"></v-textarea>
          </div>

        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snack">{{$t('messages.copied')}}</v-snackbar>
  </div>
</template>

<script>
import PaymentMethod from "@/components/payment/PaymentMethod.vue";
import paymentService from "@/service/api/payments/paymentService";

export default {
  components: {PaymentMethod},
  props: {
    mdata: {
      type: Object
    },
  },
  data() {
    return {
      snack : false,
      paymentInfoPopup : false,
      confirmationMessage : "",
      confirmationLoading : false
    }
  },
  methods: {
    copyToClipboard(str){
      if (navigator.clipboard) {
        navigator.clipboard.writeText(str).then(() => {
          this.snack = true
        })
        return
      }
    },
    getIcon(){
      switch (this.mdata.type){
        case 'BITCOIN' : return require('@/assets/payment/btc.png')
        case 'ETHEREUM' : return require('@/assets/payment/eth.png')
        case 'BCH' : return require('@/assets/payment/bch.png')
        case 'USDT' : return require('@/assets/payment/usdt.png')
        default : return 'https://cdn-icons-png.flaticon.com/512/7047/7047060.png'
      }
    },
    sendPayment(){
      this.confirmationLoading = true
      paymentService.sendNotifyAboutPayment(this.mdata.type,this.confirmationMessage)
          .then(() => {
            this.$swal(this.$t('messages.success'),'','success')
            this.confirmationMessage = ""
          })
          .catch(err => this.$swal(this.$t('errors.error'),'','error'))
          .finally(() => this.confirmationLoading = false)

    }
  },
  name: "CryptoPayment"
}
</script>

<style scoped>

</style>