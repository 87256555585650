<template>
    <div>
        <v-container>
            <user-form />
        </v-container>
    </div>
</template>

<script>
    import UserForm from "../components/user/UserForm";
    import UserService from "../service/api/userService";
    export default {
        name: "Settings",
        components: {UserForm},
        data() {
            return {
                user: null
            }
        },
        created() {
           // this.getUser()
        },
        methods: {
            getUser() {
                UserService.getUser().then(res => this.user = res.data)
            }
        },
    }
</script>

<style scoped>

</style>