<template>
    <v-menu offset-y light>
        <template v-slot:activator="{ on }">
            <v-btn
                    :outlined="!big"
                    :block="big"
                    slot="activator"
                    text
                    v-on="on"
            >
                {{currentLang}}
              <v-icon right>mdi-menu-down</v-icon>
            </v-btn>
        </template>
        <v-list>
            <v-list-item
                    v-for="(item, index) in langs"
                    :key="index"
                    @click="setLang(item)"
            >
                <v-list-tile-title>{{ item.title | upper}}</v-list-tile-title>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
    import {mapGetters} from 'vuex'
    import i18n from './../../i18n'
    export default {
        name: "lang-switcher",
        props: {
          big: {
            type: Boolean,
            default: false
          },
        },
        computed: {
            ...mapGetters([
                'userLanguage'
            ]),
            currentLang() {
                let currentCode = this.userLanguage
                let l = this.langs.find(x => x.code === currentCode)
                if (l !== undefined &&  l !== null) return this.big ? l.title : l.name
                return this.big ? "English" : "ENG"
            }
        },
        methods: {
            setLang(lang) {
                this.$store.commit("setLang",lang.code)
                i18n.locale = lang.code
                //this.$vuetify.lang.current = lang.code
            }
        },
      filters: {
        upper: function (value) {
          return value.toUpperCase();
        }
      },
        data() {
            return {
                langs: [
                    {
                        title : "English",
                        code : "en",
                        name : "ENG"
                    },
                    {
                        title : "Русский",
                        code : "ru",
                        name : "RUS"
                    },
                    // {
                    //     title : "Deutsch",
                    //     code : "de",
                    //     name : "DEU"
                    // }
                ]
            }
        },
    }
</script>

<style scoped>

</style>