<template>
    <v-container>
      <h1>{{$t('menu.BalancePurchase')}}</h1>
      <PaymentList />
    </v-container>
</template>

<script>
    import PaymentList from "@/components/payment/PaymentList";
    export default {
        name: "BalancePurchase",
      components: {PaymentList}
    }
</script>

<style scoped>

</style>