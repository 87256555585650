<template>
    <div>
        <v-navigation-drawer
                dark
                v-model="drawer"
                color="black"
                :expand-on-hover="!$vuetify.breakpoint.mobile"
                :mini-variant.sync="mini_variant"
                mini-variant-width="80"
                app
        >
          <v-img height="100" contain  src="@/assets/logo.png" />
            <user-menu class="mt-2 pink darken-4 text-center" :mini="mini_variant"></user-menu>
            <main-menu></main-menu>
        </v-navigation-drawer>
        <v-app-bar
                app
                color="black"
                dark
        >
          <v-app-bar-nav-icon @click="drawer = !drawer"  />
<!--          <h-menu />-->
          <v-toolbar-title>{{$t(`menu.${$route.name}`)}}</v-toolbar-title>
          <v-spacer></v-spacer>
          <lang-switcher/>&nbsp;

<!--          <user-menu />-->

          <!--          <template v-slot:extension>-->
<!--            <user-menu />-->
<!--          </template>-->
        </v-app-bar>

        <v-main>
<!--          <v-alert tile dense color="red">-->
<!--            {{$t('messages.alert')}}-->
<!--          </v-alert>-->
          <v-container fluid>
            <router-view></router-view>
          </v-container>
<!--            <block-menu />-->
            <LoginDialog />
        </v-main>
      <Footer />
    </div>
</template>

<script>
    import MainMenu from "./MainMenu";
    import UserMenu from "./UserMenu";
    import LangSwitcher from "./LangSwitcher";
    import BlockMenu from "./BlockMenu";
    import Footer from "./Footer";
    import HMenu from "@/components/layout/HMenu";
    import LoginDialog from "@/components/layout/LoginDialog";
    export default {
        name: "Dashboard",
        components: {LoginDialog, HMenu, Footer, BlockMenu,  LangSwitcher, UserMenu, MainMenu},
        data() {
            return {
                mini_variant : !this.$vuetify.breakpoint.mobile,
                drawer: null,
            }
        },
        methods: {

        },
    }
</script>

<style scoped>
  .content-background {
    background: #1e1e1e;
  }
  .toolbar-back {
    background: #c94b4b;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #2c162d, #641f1f);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #2c162d, #641f1f); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  }
</style>