<template>
  <div style="display: contents">
  <payment-method v-for="m in methods"
      :icon="`https://widget.donatepay.ru/images/pay/icons/${m.id}.svg`"
      :title="m.title"
      :id="m.id"
      @paymentClick="id => processPayment(id)"
  />
  </div>
</template>

<script>
import jwt from "@/plugins/jwt";
import PaymentMethod from "@/components/payment/PaymentMethod.vue";

export default {
  components: {PaymentMethod},
  props: {
    mdata: {
      type: Object
    },
  },
  name: "DonatePayPayment",
  computed: {
    methods() {
      if (!this.mdata || !this.mdata.methods) return  []
      const p = this.mdata.methods.split(",")
      return p.map(e => {
        const item = {
          id : e,
          title : e,
          min : 1,
        }
        const items = e.split(":")
        if (items.length > 1) {
          item.id = items[0]
          item.title = items[1]
        }
        if (items.length > 2){
          item.min = Number.parseInt(items[2])
        }
        return item
      })
    }
  },
  data() {
    return {
      rd : null,
      rules : [
          v => !!v || this.$t('errors.empty'),
      ],
      loading : false,
    }
  },
  methods: {
    processPayment(paymentId){
      const params = {
        callback: (amount) => this.send(paymentId, amount),
        min: this.methods.find(m => m.id === paymentId).min,
      }
      this.$emit('showAmountPopup', params)
    },
    send(paymentId, amount) {
      const uid = jwt.getUserId()
      location.href = `${this.mdata.handler}/${this.mdata.id}/${uid}/${amount}/${paymentId}`
    }
  },
}
</script>

<style scoped>
</style>