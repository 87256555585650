import {networker} from "@/service/http";

const paymentService = {
    getPayments(){
        return networker.get("payment")
    },
    sendNotifyAboutPayment(paymentType, message){
        const data = new FormData()
        data.append('type', paymentType)
        data.append('message',message)
        return networker.post("payment/notify", data)
    }
}

export default paymentService