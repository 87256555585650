<template>
    <v-hover
            v-slot:default="{ hover }"
    >
    <v-card light :elevation="hover ? 16 : 2" flat height="100%" :class="{'card-bg-free' : item.subscription.availableFree }">
      <v-row no-gutters>
        <v-col cols="12" sm="12" md="8">
          <div class="price-main" :class="{'text-center' : $vuetify.breakpoint.mobile}">
            <v-chip label color="black" dark class="title font-weight-bold pa-2">
              {{item.subscription.name}}
            </v-chip>
            <div class=" pa-3 ">
              <h2 v-if="item.oldPrice && !item.subscription.availableFree" class="mb-2 text-decoration-line-through orange--text">
                {{item.oldPrice | currencyFilter}} / {{$t('period.month')}}
              </h2>
              <h1 class="red--text"
                  v-if="!item.subscription.availableFree">{{toAmount(item.amount * 30)}} / {{$t('period.month')}}</h1>
              <h1 v-else>FREE</h1>
            </div>
            <div class="ml-3">
              <h3 v-if="!item.subscription.availableFree">{{toAmount(item.amount)}} / {{$t('period.day')}}</h3>
              <h3 v-else>{{item.subscription.freeInterval}} {{$t(`period.${item.subscription.freeIntervalType.toLowerCase()}`)}}</h3>
            </div>
          </div>
          <div  v-if="item.subscription.dayDiscounts.length > 0" class="discounts text-sm-center">
            <div class="font-weight-bold white--text subtitle-1">{{$t('base.discount')}}</div>
            <v-chip class="ma-1"  outlined v-for="discount in item.subscription.dayDiscounts"
                    dark color="white">
              {{discount.days}} days ->
              <v-avatar right color="black" class="white--text ">{{discount.percent | percentFilter}}</v-avatar>
            </v-chip>
          </div>
        </v-col>
        <v-col cols="12" sm="12" md="4" class="pa-2 justify-center text-center align-center">
          <v-img class="text-center ma-auto mt-1"  contain width="100" height="100"
                 :src="item.subscription.logoUrl"/>
           </v-col>
      </v-row>
        <v-card-actions >
          <v-row justify="space-between">
            <v-col cols="12" md="6" :class="{'text-center' : $vuetify.breakpoint.mobile}">
              <v-btn small v-if="item.subscription.type === 'CS'" :block="$vuetify.breakpoint.mobile" light
                     class="align-center mt-1" color="yellow" @click="openPacketInfoDialog(item.subscription.id)">{{$t('packet.packets')}} <v-icon>mdi-alert-octagram</v-icon></v-btn>
              <v-chip v-if="item.subscription.channelCount > 0"  dark color="red">{{item.subscription.channelCount}} {{$t('iptv.channels')}}</v-chip>
            </v-col>
            <v-col cols="12" md="6" class="text-right">
              <v-dialog persistent :fullscreen="$vuetify.breakpoint.mobile" v-model="dialog" overlay-opacity="0.8" max-width="1000">
                <template v-slot:activator="{ on }">
                  <v-btn
                      large
                      :block="$vuetify.breakpoint.mobile"
                      color="pink"
                      dark
                      v-on="on"
                  >
                    {{$t('base.pay')}} <v-icon>mdi-cart</v-icon>
                  </v-btn>
                </template>
                <v-toolbar dark color="black">
                  <v-toolbar-title>
                    {{item.subscription.name}}
                  </v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-toolbar-items>
                    <v-btn icon @click="dialog = false"><v-icon>mdi-close</v-icon></v-btn>
                  </v-toolbar-items>
                </v-toolbar>
                <v-card>
                  <v-card-text>
                    <v-row align="center" justify="center">
                      <v-col cols="12" md="6">
                        <div class="mt-3">
                          <template v-if="!item.subscription.availableFree">
                            <PricePacketPeriod
                                :show-custom-period="false"
                                :min="minPeriod"
                                :max="maxPeriod"
                                @period="(p) => period = p" />
                          </template>
                          <template v-else>
                            <h1 class="text-center orange--text">{{item.subscription.freeInterval}}
                              {{$t(`period.${item.subscription.freeIntervalType.toLowerCase()}`)}}
                            </h1>
                          </template>
                        </div>
                        <div class="mt-3">
                          <connection-select v-model="deviceFilter"
                                             :type="item.subscription.type"
                                             :max="maxConnections"/>
                        </div>
                      </v-col>
                      <!--DETAILS -->
                      <v-col cols="12" md="6">
                        <!--                                <v-progress-linear-->
                        <!--                                        class="my-3"-->
                        <!--                                        v-show="calculateLoading"-->
                        <!--                                        indeterminate-->
                        <!--                                        rounded-->
                        <!--                                        height="6"-->
                        <!--                                        color="teal"-->
                        <!--                                ></v-progress-linear>-->
                        <v-alert v-if="!payValid"  type="primary" outlined>
                          {{$t('help.not_valid_pay_period')}}
                        </v-alert>
                        <v-card  v-if="totalSummary" class="pa-4 elevation-7 mb-2 orderDetails">
                          <v-row>
                            <v-col cols="6" class="title yellow--text">{{item.subscription.name}}</v-col>
                            <v-col cols="6" class="subtitle-1 yellow--text">{{totalSummary.startPeriod}} / {{totalSummary.finishPeriod}}</v-col>
                          </v-row>
                          <v-divider />
                          <div class="mt-1" v-for="detail in totalSummary.details">
                            <v-row dense>
                              <v-col cols="6">
                                <span class="subtitle-2 font-weight-bold yellow--text">{{$t(`price.${detail.description}`)}}</span>
                              </v-col>
                              <v-col cols="6" class="font-weight-bold">
                                <!--                                                <v-chip outlined dark text-color="white" class="subtitle-2" label color="teal darken-2">-->
                                <!--                                                </v-chip>-->
                                {{detail.amount | currencyFilter}}
                              </v-col>
                            </v-row>
                          </div>
                          <div>
                            <v-row>
                              <v-col cols="6">
                                <span class="subtitle-1 font-weight-bold ">{{$t('price.total')}}</span>
                              </v-col>
                              <v-col cols="6">
                                <v-chip dark class="title"  label color="black" text-color="white">{{totalSummary.total | currencyFilter}}</v-chip>
                              </v-col>
                              <v-col cols="6" offset="6">
                                <v-spacer />
                                <v-btn :disabled="calculateLoading" :loading="payLoading || calculateLoading" @click="pay()" v-if="payValid"
                                       dark :x-large="!$vuetify.breakpoint.mobile" rounded color="indigo">{{$t('base.pay')}} <v-icon right>mdi-cart</v-icon></v-btn>
                              </v-col>
                            </v-row>
                          </div>
                        </v-card>
                      </v-col>
                    </v-row>
                    <!--v-btn v-if="item.subscription.availableFree" :loading="payLoading" @click="payFree()"
                            block dark x-large color="success">{{$t('packet.get_free_days', {days : item.subscription.freeDays})}}</v-btn-->
                  </v-card-text>
                </v-card>
              </v-dialog>
            </v-col>
          </v-row>
        </v-card-actions>
        <v-dialog v-model="packetInfoDialog"  persistent max-width="600" overlay-opacity="0.9">
            <v-toolbar dense>
                <v-toolbar-title>Packets</v-toolbar-title>
              <v-spacer />
              <v-toolbar-items>
                    <v-btn icon @click="packetInfoDialog = false"><v-icon>mdi-close</v-icon></v-btn>
                </v-toolbar-items>
            </v-toolbar>
            <v-card>
                <v-card-text>
                    <v-progress-linear v-if="packetsInfoLoading" indeterminate />
                    <packet-list :packets="packetsInfoList" />
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-card>
    </v-hover>
</template>

<script>

import DateChooser from "@/components/chooser/DateChooser";
import moment from 'moment'
import packetService from "../../service/api/packetService";
import {EventBus} from "../../service/eventBus";
import PricePacketPeriod from "./PricePacketPeriod";
import PacketList from "./PacketList";
import ConnectionSelect from "@/components/packet/ConnectionSelect";

export default {
    name: "user-price-item",
    components: {ConnectionSelect, PacketList, PricePacketPeriod, DateChooser},
    props: {
        accountId: {
            type: String,
        },
        item: {
            type: Object,
        },
    },
  computed: {
      maxConnections() {
        if (!this.item) return 1;
        return this.item.subscription.availableFree ? 1 : this.item.subscription.type === 'CS' ? 2 : -1
      },
      minPeriod(){
        if (this.item.subscription.availableFree) return 1
        if (this.item.subscription.type === 'IPTV') return 30
        return 1
      },
      maxPeriod(){
        if (this.item.subscription.availableFree) return this.item.subscription.freeInterval
        /*if (this.item.subscription.type === 'IPTV') return 90
        return 365*/ return 90
      }
    },
    watch: {
        period(newValue, oldValue) {
            this.calculateTotalPrice()
        },
        deviceFilter(n, o) {
            this.calculateTotalPrice()
        }
    },
    data() {
        return {
            packetInfoDialog : false,
            packetsInfoList : [],
            packetsInfoLoading : false,
            dialog: false,
            calculateLoading: false,
            payLoading: false,
            payValid: false,
            totalSummary: null,
            deviceFilter: '',
            period: 1,

        }
    },
    methods: {
        maxDiscount(discounts) {
            return Math.max.apply(Math, discounts.map(function (o) {
                return o.percent;
            }))
        },
        toAmount(a) {
            return this.$options.filters.currencyFilter(a)
        },
        pay() {
            this.payLoading = true
            packetService.payPacket(
                this.accountId,
                this.item.subscription.id,
                this.period,
                this.deviceFilter)
                .then((res) => {
                    this.$emit('onBuy')
                    EventBus.$emit('PAY')
                    this.dialog = false
                    this.$swal(this.$t('base.success'), this.$t('help.pay_success'), 'success')
                })
                .catch((error) => {
                    const code = error.response.data.code
                    this.$swal(this.$t('errors.error'), this.$t(`errors.${code}`), 'error')
                })
                .finally(() => this.payLoading = false)
        },
        openPacketInfoDialog(group){
            if (this.packetsInfoList.length === 0){
                this.packetsInfoLoading = true
                packetService.getPacketInfoBySub(group)
                    .then(res => this.packetsInfoList = res.data)
                    .finally(() => this.packetsInfoLoading = false)
            }
            this.packetInfoDialog = true
        },
        calculateTotalPrice() {
            this.calculateLoading = true
            //this.totalSummary = null
            packetService.getPacketTotalPrice(
                this.accountId,
                this.item.subscription.id,
                this.period,
                this.deviceFilter)
                .then((res) => {
                    this.totalSummary = res.data
                    this.payValid = true
                })
                .catch((error) => {
                    this.payValid = false
                })
                .finally(() => this.calculateLoading = false)
        },
        // setPeriod(days) {
        //     this.customPeriod = false
        //     const start = new Date().toISOString().substr(0, 10)
        //     const finish = moment(start).add(days,'d').format('YYYY-MM-DD')
        //     this.start = start
        //     this.finish = finish
        // }
    },
}
</script>

<style scoped>
    .orderDetails {
      background: linear-gradient(21deg, #450b6c 0%, #ac3636 50%, #3e115a 100%);
    }
    .card-bg {
      /*background: linear-gradient(90deg, #5d2781 0%, #ac3636 50%, #bb67ef 100%);*/
      background: #c94b4b;  /* fallback for old browsers */
      background: -webkit-linear-gradient(to right, #4b134f, #000000);  /* Chrome 10-25, Safari 5.1-6 */
      background: linear-gradient(to right, #4b134f, #000000); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

      position: relative;
    }

    .card-bg-free {
      /*background: #c94b4b;  !* fallback for old browsers *!*/
      /*background: -webkit-linear-gradient(to right, #133c4f,#000000);  !* Chrome 10-25, Safari 5.1-6 *!*/
      /*background: linear-gradient(to right, #133c4f,#000000); !* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ *!*/

      /*position: relative;*/
      background: #b2b2b4 !important;
    }
    /*.price-main{*/
    /*  display: inline-block;*/
    /*  right: 0;*/
    /*  position: absolute;*/
    /*  bottom: 0;*/
    /*}*/
    /*.discounts {*/
    /*  position: absolute;*/
    /*  top: 0;*/
    /*  right: 0;*/
    /*}*/
</style>