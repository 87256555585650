<template>
  <div style="display: contents">
    <payment-method  v-for="m in methods"
      :icon="`https://stuff.ipcast.xyz/img/payment/fk/${m.id}.png`"
      :id="m.id"
      :title="m.name"
      @paymentClick="id => processPayment(id)"
    />
  </div>
</template>

<script>
import freeKassaService from "@/service/api/payments/freeKassaService";
import jwt from "@/plugins/jwt";
import PaymentMethod from "@/components/payment/PaymentMethod.vue";

export default {
  name: "FreeKassaPayment",
  components: {PaymentMethod},
  props: {
    mdata : {
      type : Object,
    },
  },
  created() {
    this.getMethods()
  },
  data() {
    return {
      methods: []
    }
  },
  methods: {
    processPayment(paymentId){
      const params = {
        callback : (amount) => this.send(paymentId, amount)
      }
      this.$emit('showAmountPopup', params)
    },
    getMethods() {
      freeKassaService.getMethods(this.mdata.id).then(res => {
        this.methods = res.data
      })
    },
    send(paymentId, amount){
      if (this.amount === 0) {
        return
      }
      const uid = jwt.getUserId()
      const st=`${this.mdata.handler}/${this.mdata.id}/${uid}/${amount}/${paymentId}`
      location.href=st
    }
  },
}
</script>

<style scoped>
</style>