<script>

export default {
  name: "PaymentAmountPopup",
  computed: {
    rules() {
      return [
        v => !!v || this.$t('errors.empty'),
        v => v > 0 && v <= 25 || this.$t('errors.maxPurchase'),
        v => v >= this.minValue ||  this.$t('errors.minPurchase', {value : this.minValue})
      ]

    }
  },
  data() {
    return {
      showed: false,
      amount : 0,
      valid : false,
      callback : () => {},
      minValue : 1,
    }
  },
  methods: {
    show(params) {
      this.callback = params.callback
      if (params.min) this.minValue = params.min
      this.showed = true
    },
    select(){
      this.callback(this.amount)
      this.showed = false
      this.amount = 0
    }
  },
}
</script>

<template>
  <v-dialog v-model="showed" max-width="400" overlay-opacity="0.9">
    <v-card>
      <v-card-title>{{$t('price.amountPurchase')}}
        <v-spacer />
        <v-btn icon @click="showed = false"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-card-text>
        <v-form v-model="valid">
          <v-text-field id="amountPurchase" dense prefix="€"
                        outlined v-model="amount"
                        single-line
                        :rules="rules" type="number"
                        :label="$t('price.amountPurchase')" />
        </v-form>
        <v-row class="mt-2">
          <v-btn block :disabled="!valid" @click="select" large color="success">{{$t('price.payment_process')}}</v-btn>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<style scoped>

</style>