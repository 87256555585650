<script>

export default {
  name: "PaymentMethod",
  props: {
    id : {
      type : String,
    },
    title: {
      type: String,
    },
    icon: {
      type: String,
    },
  },
  methods: {

  },
}
</script>

<template>
      <div class="widget text-center" @click="$emit('paymentClick', id)">
        <div class="d-flex justify-center">
          <v-img contain :src="icon" height="120" width="120"/>
        </div>
        <span class="black--text text-caption font-weight-bold">{{title}}</span>
      </div>
</template>

<style scoped>
  .widget {
    margin: 0.25em;
    border: 3px solid #ffffff;
    padding: 0.5em;
    border-radius: 1em;
    max-width: 150px;
  }

  .widget:hover {
    border: 3px solid  #e40086;
    cursor: pointer;
    transition:  0.3s;
  }
</style>