<template>
    <v-list nav >
        <v-list-item v-for="item in items" :to="item.path"  active-class="pink--text">
            <v-list-item-action>
                <v-icon>{{item.meta.icon}}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
                <v-list-item-title>{{$t(`menu.${item.name}`)}}</v-list-item-title>
            </v-list-item-content>
        </v-list-item>
        <v-list-item @click="logout">
            <v-list-item-action>
                <v-icon>mdi-logout</v-icon>
            </v-list-item-action>
            <v-list-item-content>
                <v-list-item-title>{{$t('menu.Logout')}}</v-list-item-title>
            </v-list-item-content>
        </v-list-item>
    </v-list>
</template>

<script>
    import authService from "../../service/api/authService";
    import {menu} from './../../router/routes'
    export default {
        name: "main-menu",
        computed: {
            items() {
                return menu;
            }
        },
        methods: {
            logout() {
                authService.logout()
                this.$router.push("/login")
            }
        },
    }
</script>

<style scoped>

</style>