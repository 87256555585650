<template>
  <div>
    <v-dialog v-model="showed" persistent>
      <v-toolbar>
        {{$t('packet.packets')}}
        <v-spacer />
        <v-toolbar-items>
          <v-btn icon @click="showed = false"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar-items>
      </v-toolbar>
    <v-card>
      <v-card-text>
        <v-data-table
            hide-default-footer
            disable-pagination
            :items="items"
            :headers="headers">
          <template v-slot:item.name="{item}">
            <strong class="orange--text">{{item.name}}</strong>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    </v-dialog>
  </div>
</template>
<script>
import packetService from "../../service/api/packetService";

export default {
  name: "PacketListExtended",
  props: {
    accountId: {
      type: String
    },
  },
  computed: {
    items() {
      if (!this.packets) return []
      let arr = []
      for (const [key, value] of Object.entries(this.packets)) {
        const d = {}
        d.name = key
        d.caid = value[0].packet.caid
        d.ident = value[0].packet.ident
        d.camd3 = value.find((e,i,a) => e.packetPort === 'CAMD3').port
        d.newcamd = value.find((e,i,a) => e.packetPort === 'NEWCAMD').port
        d.cccam = value.find((e,i,a) => e.packetPort === 'CCCAM').port
        d.mgcamd = value.find((e,i,a) => e.packetPort === 'MGCAMD').port
        arr.push(d)
      }
      return arr
    }
  },
  data() {
    return {
      headers : [
        {text : '', value : 'name'},
        {text : 'caid', value : 'caid',class: 'orange--text text-uppercase'},
        {text : 'ident', value : 'ident',class: 'orange--text text-uppercase'},
        {text : 'camd3 port', value : 'camd3',class: 'orange--text text-uppercase'},
        {text : 'newcamd port', value : 'newcamd',class: 'orange--text text-uppercase'},
        {text : 'cccam port', value : 'cccam',class: 'orange--text text-uppercase'},
        {text : 'mgcamd port', value : 'mgcamd',class: 'orange--text text-uppercase'},

      ],
      config : {},
      packets : [],
      showed : false,
    }
  },
  created() {
    if (this.accountId) this.load()
  },
  methods: {
    load(){
      this.getPacketInfo()
    },
    getPacketInfo(){
      packetService.getPacketInfo(this.accountId).then(res => this.packets = res.data)
    }
  },
}
</script>

<style scoped>

</style>