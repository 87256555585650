<template>
  <v-card>
    <v-card-text class="text-center">
      <div>{{$t('price.balance')}}</div>
      <h2 class="yellow--text display-1 ">{{balance}}€</h2>
      <v-btn class="mt-1"  outlined to="/balance">{{$t('price.purchase')}}</v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
import userService from "../../../service/api/userService";
export default {
  name: "BalanceOverviewCard",
  data() {
    return {
      balance: 0
    }
  },
  created() {
    this.updateBalance()
  },
  methods: {
    updateBalance() {
      userService.getBalance().then(res => this.balance = res.data)
    }
  },
}
</script>

<style scoped>

</style>