import { render, staticRenderFns } from "./ConfigInstruction.vue?vue&type=template&id=f799a30c&scoped=true&"
import script from "./ConfigInstruction.vue?vue&type=script&lang=js&"
export * from "./ConfigInstruction.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f799a30c",
  null
  
)

export default component.exports