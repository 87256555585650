<template>
    <div>
        <NewsList />
    </div>
</template>

<script>
    import NewsList from "./NewsList";
    export default {
        name: "NewsBlock",
        components: {NewsList}
    }
</script>

<style scoped>

</style>