<template>
    <div>
      <v-dialog v-model="showed"  persistent overlay-opacity="0.9">
        <v-toolbar dense dark >
          <v-toolbar-title>{{$t('help.device_info')}}</v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon @click="showed = false"><v-icon>mdi-close</v-icon></v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card>
          <v-btn-toggle  mandatory color="orange" v-model="tabs">
            <v-btn small v-for="(urls,port) in config">{{port}}</v-btn>
          </v-btn-toggle>
          <div>
            <div class="mt-1">
              <strong class="ml-2 yellow--text">{{types[tabs]}}</strong>
              <div class="pa-2" style="word-break: break-all; white-space: pre-line ">
                {{config[types[tabs]]}}
              </div>
            </div>
          </div>
        </v-card>
      </v-dialog>
    </div>
</template>

<script>
    import AccountService from "../../service/api/accountService";

    export default {
        name: "ConfigInstruction",
        props: {
            accountId: {
                type: String
            },
        },
        created() {
          if (this.accountId) this.load()
        },
      computed: {
        types() {
          if (!this.config) return ['#NONE']
          return Object.keys(this.config)
        }
      },
      data() {
        return {
          showed: false,
          config : {},
          tabs : 0,
        }
      },
        methods: {
            load(){
              this.getConfig()
            },
            getConfig() {
                AccountService.getConfig(this.accountId).then(res => this.config = res.data)
            },
        },
    }
</script>

<style scoped>

</style>