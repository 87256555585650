<template>
    <div>
        <v-overlay :value="loading">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
<!--        <v-alert text v-if="packets && packets.length === 0" type="error" class="mt-1 text-uppercase">-->
<!--            {{$t('packet.empty')}}-->
<!--        </v-alert>-->
        <v-container>
            <v-row>
                <v-col cols="12" sm="6"  v-if="packets" v-for="packet in packets">
                    <subscription-widget :packet="packet" light  />
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import packetService from "../../service/api/packetService";
    import {EventBus} from "@/service/eventBus";
    import SubscriptionWidget from "@/components/packet/SubscriptionWidget.vue";

    export default {
        name: "user-packets-list",
      components: {SubscriptionWidget},
      props: {
            accountId: {
                type: String
            },
        },
        created(){
            this.getUserPackets()
        },
        data() {
            return {
                loading : false,
                packets: false
            }
        },
        methods: {
            togglePause(packetId){
                this.$swal.fire({
                    title: this.$t('base.sure'),
                    type: 'warning',
                    showCancelButton: true,
                    cancelButtonText: this.$t('base.cancel'),
                    confirmButtonText: this.$t('base.confirm')
                }).then((result) => {
                    if (result.value) {
                        this.loading = true
                        packetService.togglePlayPause(packetId).then(res => {
                            this.$swal(this.$t('base.success'), this.$t('base.done'), 'success')
                            this.getUserPackets()
                        }).catch(error => {
                            this.$swal.fire(this.$t('errors.error'), this.$t(`errors.${error.response.data.code}`), 'error')
                        }).finally(() => this.loading = false)
                    }
                })
            },
            refund(packetId){
                this.$swal.fire({
                    title: this.$t('base.sure'),
                    type: 'warning',
                    showCancelButton: true,
                    cancelButtonText: this.$t('base.cancel'),
                    confirmButtonText: this.$t('base.confirm')
                }).then((result) => {
                    if (result.value) {
                        this.loading = true
                        packetService.refund(packetId).then(res => {
                            this.$swal(this.$t('base.success'), this.$t('base.done'), 'success')
                            EventBus.$emit("REFUND")
                            this.getUserPackets()
                        }).catch(error => {
                            this.$swal.fire(this.$t('errors.error'), this.$t(`errors.${error.response.data.code}`), 'error')
                        }).finally(() => this.loading = false)
                    }
                })
            },
            getUserPackets() {
                this.loading = true
                packetService.getUserPackets(this.accountId)
                    .then(res => {
                      this.packets = res.data
                      this.$emit('loadDone',res.data)
                    })
                    .finally(() => this.loading = false)
            },
        },
    }
</script>

<style scoped>

</style>