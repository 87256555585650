<template>
    <div>
        <div class="blue-grey darken-4 pa-3 white--text title text-uppercase">
            {{username}}
        </div>
        <v-tabs
                background-color="blue-grey darken-2"
                hide-slider
                grow
                icons-and-text
                dark>
            <v-tab
                    v-for="page in routes"
                    :key="page.name"
                    exact
                    exact-active-class="orange darken-3 black--text"
                    :to="`/user/${$route.params.id}/${page.path}`"
            >
                {{$t(`menu.${page.name}`)}}
                <v-icon>{{page.meta.icon}}</v-icon>
            </v-tab>
        </v-tabs>
        <v-container>
            <router-view></router-view>
<!--            <component :is="pages[tabs].component" :key="pages[tabs].name" :id="userId"></component>-->
        </v-container>
    </div>
</template>

<script>
    import {routes} from '@/router/routes'
    import UserService from "../service/api/userService";
    export default {
        name: "User",
        computed: {
            userId() {
                return this.$route.params.id;
            },
            routes(){
                console.log(routes)
                return routes.find(r => r.name === 'Dashboard').children.find(r => r.name === 'User').children
            }
        },
        created(){
          this.getUser(this.$route.params.id)
        },
        data() {
            return {
                tabs : 0,
                username : 'user'
            }
        },
        methods: {
            getUser(id) {
                UserService.getUserById(id).then(res => this.username = res.data.login)
            }
        },
    }
</script>

<style scoped>

</style>