<template>
  <v-card>
    <v-card-text class="text-center">
      <div class="orange--text display-1"><v-icon left>mdi-account</v-icon>{{username}}</div>
      <div class="orange--text"><v-icon left>mdi-mail</v-icon>{{user.email}}</div>
      <v-btn to="/settings" class="mt-1" outlined>{{$t('base.edit')}}</v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
import userService from "../../../service/api/userService";
export default {
  name: "UserOverviewCard",
  created() {
    this.load()
  },
  computed: {
    username() {
      if (!this.user.login) return ''
      return this.user.login.length > 10 ? this.user.login.slice(0,9) + "..." : this.user.login
    }
  },
  data() {
    return {
      user : {},
    }
  },
  methods: {
    load() {
      userService.getUser().then(res => this.user = res.data)
    }
  },
}
</script>

<style scoped>

</style>