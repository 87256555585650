import { render, staticRenderFns } from "./CryptoPayment.vue?vue&type=template&id=cc6b74aa&scoped=true&"
import script from "./CryptoPayment.vue?vue&type=script&lang=js&"
export * from "./CryptoPayment.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cc6b74aa",
  null
  
)

export default component.exports