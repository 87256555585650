import moment from 'moment/moment'
import Vue from 'vue'


Vue.filter("euroFilter", str => str + 'â‚¬')
Vue.filter("currencyFilter", n => {
    const formatter = new Intl.NumberFormat('it-IT', {
        style: 'currency',
        currency: 'EUR'
    })
    return  formatter.format(parseFloat(n))
    //n.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
})
Vue.filter("percentFilter", str => str + '%')
Vue.filter('formatDateFilter', str => moment.unix(str / 1000).format('DD.MM.YYYY HH:mm'))
Vue.filter('formatISODateFilter', str => moment(str,"YYYY-MM-DD").format('DD.MM.YYYY'))