<template>
  <div>
    <v-container fluid fill-height>
      <v-row justify="center">
        <v-col cols="12" md="6">
          <user-overview />
        </v-col>
        <v-col cols="12" md="6" >
          <NewsBlock />
        </v-col>
        <v-col cols="12">
          <Faq />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import NewsBlock from "../components/news/NewsBlock";
import UserInfo from "./Cabinet/UserInfo";
import Faq from "./Faq";
import UserOverview from "../components/user/UserOverview";
export default {
  components: {
    UserOverview,
    Faq,
    UserInfo,
    NewsBlock
  },
};
</script>
