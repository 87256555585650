import {networker,refreshAccessToken} from './../http'
import store from './../../store'
import {ACCESS_TOKEN_KEY,REFRESH_TOKEN_KEY} from "../constraints";
import jwt from "../jwt";

const AUTH_URL = `${process.env.VUE_APP_API_BASE}/api/token/`
const FORGOT_URL = `${process.env.VUE_APP_API_BASE}/api/register/forgot/`
const VERIFY_URL = `${process.env.VUE_APP_API_BASE}/api/token/verify/`
const CAPTCHA_SETTINGS_URL = `${process.env.VUE_APP_API_BASE}/api/token/captcha/`
const RECOVER_URL = `${process.env.VUE_APP_API_BASE}/api/register/recover`

export default  {
    signIn(login,password,token){
        const data = new FormData()
        data.append("login", login)
        data.append("password", password)
        data.append("verify", token)
        return new Promise((resolve, reject) => {
            networker.post(AUTH_URL,data)
                .then(response => {
                    if (response.status === 200 && response.data.access) {
                        localStorage.setItem(ACCESS_TOKEN_KEY, response.data.access);
                        localStorage.setItem(REFRESH_TOKEN_KEY, response.data.refresh);
                        store.commit('initToken');
                        resolve(response.data)
                    } else reject({response : {data : {code : "ERROR", error : "Invalid credentials"}}})
                })
                .catch(error => reject(error))
        })
    },
    verify(code,captcha = ''){
        const data = new FormData()
        data.append("verify", code)
        //data.append("captcha", captcha)
        return new Promise((resolve, reject) => {
            networker.post(VERIFY_URL,data)
                .then(response => {
                    if (response.status === 200 && response.data.access) {
                        localStorage.setItem(ACCESS_TOKEN_KEY, response.data.access);
                        localStorage.setItem(REFRESH_TOKEN_KEY, response.data.refresh);
                        store.commit('initToken');
                        resolve(response.data)
                    } else reject({response : {data : {code : "VERIFY_INVALID", error : "Invalid code"}}})
                })
                .catch(error => reject(error))
        })
    },
    logout(){
        localStorage.clear();
        store.commit('initToken');
    },
    recover(token, password, captcha){
        const data = new FormData()
        data.append("password", password)
        data.append("captcha", captcha)
        data.append("token", token)
        return networker.post(`${RECOVER_URL}`,data)
    },
    forgot(email,code){
        const data = new FormData()
        data.append("email", email)
        data.append("verify", code)
        return networker.post(`${FORGOT_URL}`,data)
    },
    captchaSettings() {
        return networker.get(CAPTCHA_SETTINGS_URL)
    },
    isAuth(){
        return !!localStorage.getItem(ACCESS_TOKEN_KEY);
    },
    checkTokensExpire(){
        if (!!localStorage.getItem(REFRESH_TOKEN_KEY)){
            refreshAccessToken().catch(e => {
                this.logout()
            })
        }

    },
}