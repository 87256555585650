<script>
export default {
  name: "SubscriptionsSimpleTable",
  props: {
    subscriptions: {
      type: Array,
    },
  },
}
</script>

<template>
  <v-simple-table>
    <template v-slot:default>
      <thead>
      <tr>
        <th class="text-left">{{$t('base.name')}}</th>
        <th class="text-left">{{$t('packet.from')}}</th>
        <th class="text-left">{{$t('packet.to')}}</th>
        <th class="text-left">{{$t('price.total')}}</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="item in subscriptions" :key="item.id">
        <td class="font-weight-bold">{{ item.subscription.name }}</td>
        <td>{{ item.from }}</td>
        <td>{{ item.to }}</td>
        <td><v-chip v-if="item.transaction">{{ item.transaction.amount | currencyFilter}}</v-chip></td>
      </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<style scoped>

</style>