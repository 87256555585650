<template>
    <div>
        <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value="value"
                transition="scale-transition"
                offset-y
                full-width
                max-width="290px"
                min-width="290px"
        >
            <template v-slot:activator="{ on }">
                <v-text-field
                        dense
                        :clearable="clearable"
                        @click:clear="updateValue(null)"
                        outlined
                        v-model="formattedDate"
                        :label="label"
                        prepend-inner-icon="mdi-timer"
                        readonly
                        v-on="on"
                >
                    <!--template slot="append" v-if="clearable && !!value">
                        <v-btn icon @click="updateValue(null)">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </template-->
                </v-text-field>
            </template>
            <v-date-picker
                    @change="updateValue"
                    v-model="value"
                    no-title
                    @input="menu = false"
                    :min="startToday ? today : null"
                    scrollable
            >
                <div class="flex-grow-1"></div>
<!--                <v-btn text color="primary" @click="menu = false">Cancel</v-btn>-->
<!--                <v-btn text color="primary" @click="$refs.menu.save(value)">OK</v-btn>-->
            </v-date-picker>
        </v-menu>
    </div>
</template>

<script>
    import moment from 'moment'
    export default {
        name: "date-chooser",
        props: {
            value : {
                type : String
            },
            clearable : {
                type : Boolean,
                default : false
            },
            label: {
                type: String,
                default: 'Date'
            },
            startToday : {
                type : Boolean,
                default: false,
            }
        },
        computed: {
            formattedDate() {
                return this.value ? moment(this.value).format('DD.MM.YYYY') : ''
            }
        },
        data() {
            return {
                today : new Date().toISOString().substr(0, 10),
                menu: null,
                //date : new Date().toISOString().substr(0, 10),
            }
        },
        methods: {
            updateValue(v) {
                this.$emit('input',v)
            }
        },
    }
</script>

<style scoped>

</style>