<template>
  <v-app class="app">
<!--    <Dashboard v-if="isAuth"></Dashboard>-->
<!--    <login v-else ></login>-->
    <router-view></router-view>
  </v-app>
</template>

<script>

import authService from "./service/api/authService";

export default {
  name: 'App',
  data: () => ({
    //
  }),
    created() {
        authService.checkTokensExpire()
    },
    computed: {
    isAuth() {
      return authService.isAuth()
    },
  },
    methods: {
    },
};
</script>
<style>
 /*.v-application {*/
 /*  font-family: 'Ubuntu', sans-serif !important;*/
 /*}*/
</style>
