<template>
  <div>
    <payment-amount-popup ref="amountPopup" />
    <v-card elevation="12" light>
      <v-card-text>
        <div v-if="!loading && payments.length === 0" class="text-center title mt-3">
          {{$t('transaction.no_wallets')}}
        </div>
        <div class="d-flex flex-wrap">
          <template v-for="p in payments">
            <component :is="getPaymentComponent(p.type)" :valid="valid" :mdata="p"
                       :amount="amount" @showAmountPopup="(params) => $refs.amountPopup.show(params)"/>
          </template>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import paymentService from "@/service/api/payments/paymentService";
import DonatePayPayment from "@/components/payment/donatePay/DonatePayPayment";
import DonattyPayment from "@/components/payment/donatty/DonattyPayment";
import FreeKassaPayment from "@/components/payment/freekassa/FreeKassaPayment";
import CustomPayment from "./custom/CustomPayment";
import PaymentAmountPopup from "@/components/payment/PaymentAmountPopup.vue";
import CryptoPayment from "@/components/payment/bitcoin/CryptoPayment.vue";

export default {
  name: "PaymentList",
  components: {PaymentAmountPopup},
  created() {
    this.getPayments()
  },
  data() {
    return {
      payments: [],
      loading : false,
      valid : false,
      amount : 0.00,
      rules : [
        v => !!v || this.$t('errors.empty'),
          v => v > 0 && v <= 25 || this.$t('errors.maxPurchase')
      ],
    }
  },
  methods: {
    getPaymentComponent(type){
      switch (type) {
        case "DONATE_PAY" :
          return DonatePayPayment
        case "BITCOIN":
        case "ETHEREUM":
        case "BCH":
        case "USDT":
          return CryptoPayment
        case "FREEKASSA" :
          return FreeKassaPayment
        case "DONATTY" :
          return DonattyPayment
        case "CUSTOM" :
          return CustomPayment
      }
    },
    getPayments() {
      this.loading = true
      paymentService.getPayments()
          .then(res => this.payments = res.data)
          .finally(() => this.loading = false)
    }
  },
}
</script>

<style scoped>

</style>