import Vue from 'vue'
import Vuex from 'vuex'
import {ACCESS_TOKEN_KEY,LANG_KEY} from './service/constraints'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    auth: {
      token:null
    },
    language : process.env.VUE_APP_LANG,
  },
  mutations: {
    initToken(state){
        state.auth.token = localStorage.getItem(ACCESS_TOKEN_KEY) ? localStorage.getItem(ACCESS_TOKEN_KEY) : null
    },
    initLanguage(state){
        state.language = localStorage.getItem(LANG_KEY) ? localStorage.getItem(LANG_KEY) : process.env.VUE_APP_LANG
    },
    setLang(state,lang){
      state.language = lang
      localStorage.setItem(LANG_KEY,lang)
    }
  },
  actions: {

  },
  getters : {
    userLanguage : (state) => state.language,
    token : state => state.auth.token
  }
})
