// Create VueI18n instance with options
import VueI18n from 'vue-i18n'
import ru from "./ru";
import de from "./de"
import en from './en'
import store from './../store'
import Vue from "vue";

const messages = {
    ru : ru,
    de : de,
    en : en
}

store.commit('initLanguage');
const currentLanguage = store.getters.userLanguage
Vue.use(VueI18n)

const i18n = new VueI18n({
    locale: currentLanguage, // set locale
    messages, // set locale messages
})

export default i18n