<template>
    <div>
        <user-log :handler="handler" />
    </div>
</template>

<script>
    import AccountService from "../../service/api/accountService";
    import HistoryLog from "../../views/HistoryLog";
    import UserLog from "../user/UserLog";
    export default {
        name: "AccountHistory",
        components: {UserLog, HistoryLog},
        props: {
            id: {
                type: String,
            },
        },
        computed: {
            handler() {
                return (pagination,filters) => AccountService.getLog(this.id,pagination,filters);
            }
        },
    }
</script>

<style scoped>

</style>