import {networker} from "../http";
import userService from "./userService";

const packetService = {
    getUserPackets(accountId){
        return networker.get(`packet/${accountId}`)
    },
    getArchivePackets(accountId){
        return networker.get(`packet/${accountId}/archive`)
    },
    getDeferredPackets(accountId){
        return networker.get(`packet/${accountId}/deferred`)
    },
    getUserPriceList(accountId){
        return networker.get(`packet/${accountId}/market`)
    },
    payFree(){
        return networker.post(`packet/free`)
    },
    payPacket(accountId,groupId,period,filterId){
        const data = new FormData()
        data.append('account',accountId)
        data.append('packet_group_id',groupId)
        data.append('days',period)
        data.append('device_filter_id',filterId)
        return networker.post(`packet/`,data)
    },
    togglePlayPause(packetId){
        return networker.post(`packet/pause/${packetId}`)
    },
    refund(packetId){
        return networker.post(`packet/refund/${packetId}`)
    },
    getPacketTotalPrice(accountId,groupId,period,filterId){
        return networker.get('packet/total',{
            params : {
                account : accountId,
                packet_group_id : groupId,
                days : period,
                device_filter_id : filterId

            }
        })
    },
    getFreePackets(){
        return networker.get(`packet/free`)
    },
    getPacketInfo(accountId){
        return networker.get(`packet/${accountId}/info`)
    },
    getPacketInfoBySub(subId){
        return networker.get(`packet/info/group/${subId}`)
    }
}

export default packetService