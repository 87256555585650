<template>
  <v-main class="back">
    <v-container
        fluid
        fill-height
    >
      <v-layout
          align-center
          justify-center
      >
        <v-flex
            xs12
            sm8
            md4
        >
          <!--                    <v-layout-->
          <!--                            align-center-->
          <!--                            justify-center>-->
          <!--                        <v-img-->
          <!--                                src="@/assets/logo.png"-->
          <!--                                contain-->
          <!--                                max-width="200"-->
          <!--                                max-height="200"-->
          <!--                        />-->
          <!--                    </v-layout>-->
          <div>
            <v-row no-gutters class="pr-4">
              <v-spacer/>
              <lang-switcher></lang-switcher>
            </v-row>
            <v-form ref="form" @submit.prevent="recover()">
              <v-card-text>
                <p>{{ $t('auth.recover_msg') }}</p>
                <v-text-field
                    solo
                    v-model="email"
                    :rules="requiredRule"
                    :label="$t('base.email')"
                    prepend-inner-icon="mdi-email"
                    type="text"
                ></v-text-field>
                <v-row>
                  <v-col cols="12">
                    <div class="d-flex justify-center">
                      <captcha
                          ref="captchaWidget"
                          @verify="r => captcha = r"
                          @expired="captcha = ''"
                      />
                    </div>
                  </v-col>
                  <v-col cols="12" class="text-right">
                    <v-btn type="submit" block :loading="loading" x-large color="pink">
                      {{ $t('auth.recover') }}
                      <v-icon right>mdi-account-key</v-icon>
                    </v-btn>
                    <v-btn to="/login" text block x-large color="white">
                      {{ $t('auth.signIn') }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-form>
          </div>
        </v-flex>
      </v-layout>
      <footer/>
    </v-container>
  </v-main>
</template>
<script>
import LangSwitcher from "@/components/layout/LangSwitcher";
import authService from "@/service/api/authService";
import Footer from "@/components/layout/Footer";
import Captcha from "../../components/catcha/captcha.vue";

export default {
  name: "Forgot",
  components: {Captcha, Footer, LangSwitcher},
  created() {

  },
  computed: {
  },
  data() {
    return {
      captcha: '',
      email: "",
      loading: false,
      requiredRule: [
        v => !!v || this.$t('errors.empty'),
        v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || this.$t('errors.email')
      ],
    }
  },
  methods: {
    recover() {
      if (!this.validate()) return
      this.loading = true
      authService.forgot(this.email, this.captcha)
          .then((res) => {
            this.$swal(this.$t('base.success'), this.$t('messages.forgot_success'), 'success')
          })
          .catch((error) => {
            this.$swal(this.$t('errors.error'), this.$t('errors.' + error.response.data.code), 'error')
          })
          .finally(() => {
            this.loading = false
            this.$refs.captchaWidget.reset()
            this.captcha = ''
          })
    },
    validate() {
      if (!this.$refs.captchaWidget.valid()) {
        this.$swal(this.$t('errors.error'), this.$t('errors.captcha'), 'error')
        return false
      }
      return this.$refs.form.validate()
    }
  },
}
</script>

<style scoped>
.back {
  background-image: linear-gradient(-225deg, #231557 0%, #44107A 29%, #FF1361 100%);
}
</style>