<template>
    <component :is="widget" @refresh="refresh" :account="account" />
</template>

<script>
    import IptvSubscriptionsWidget from "@/components/packet/subscription/IptvSubscriptionsWidget.vue";
    import CSSubscriptionWidget from "@/components/packet/subscription/CSSubscriptionWidget.vue";
    export default {
        name: "user-packets",
        components: {},
        props: {
            account: {
                type: Object, //account id
            },
        },
        data() {
            return {
            }
        },
        created() {},
      computed: {
          widget(){
            if (this.account.provider === 'CS') return CSSubscriptionWidget
            return IptvSubscriptionsWidget
          },
      },
        mounted(){
        },
        methods: {
            refresh(){
                this.$emit('refresh')
            },

        },
    }
</script>

<style scoped>

</style>