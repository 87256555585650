import axios from 'axios'
import router from './../router'
import store from './../store'
import {ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY} from './../service/constraints'
import {EventBus, EventBusCommands} from "@/service/eventBus";

export const networker = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
})

const AUTH_URL = `${process.env.VUE_APP_API_BASE}/api/token`

let isRefreshing = false;
let refreshSubscribers = [];

const hasRefreshToken = () => !!localStorage.getItem(REFRESH_TOKEN_KEY)
const hasAccessToken = () => !!localStorage.getItem(ACCESS_TOKEN_KEY)

networker.interceptors.request.use(function (config) {
    if (process.env.NODE_ENV === 'development') console.log(config)
    if (hasAccessToken()) config.headers['Authorization'] = 'Bearer ' + localStorage.getItem(ACCESS_TOKEN_KEY);
    return config;
}, function (error) {
    return Promise.reject(error);
});

networker.interceptors.response.use(response => {
    if (process.env.NODE_ENV === 'development') console.log(response)
    return response;
}, error => {
    if (process.env.NODE_ENV === 'development') console.log(error)
    const {config, response: {status}} = error;
    const originalRequest = config;
    if ((status === 401 || status === 403) && hasRefreshToken) {
        if (!isRefreshing) {
            isRefreshing = true;
            refreshAccessToken()
                .then(newToken => onRefreshed(newToken))
                .catch(error => {
                    EventBus.$emit(EventBusCommands.R_TOKEN_EXPIRED)
                    clearStorage()
                })
                .finally(() => {
                    isRefreshing = false;
                   // refreshSubscribers = []
                });
        }

        return new Promise((resolve, reject) => {
            subscribeTokenRefresh(token => {
                originalRequest.headers['Authorization'] = 'Bearer ' + token;
                resolve(axios(originalRequest));
            });
        });
    } else {
        return Promise.reject(error);
    }
});

export function refreshAccessToken() {
    return new Promise((resolve, reject) => {
        console.log("refresh token")
        if (!hasRefreshToken) reject("refresh token not found")

        axios.post(`${AUTH_URL}/refresh`,{},{
            headers: {
                token: localStorage.getItem(REFRESH_TOKEN_KEY)//the token is a variable which holds the token
            }
        }).then(response => {
            if (response.data.access)  localStorage.setItem(ACCESS_TOKEN_KEY, response.data.access)
            if (response.data.refresh) localStorage.setItem(REFRESH_TOKEN_KEY, response.data.refresh)
            if (response.data.access) resolve(response.data.access)
        }).catch(error => { reject("fuck")})
    })
}

function clearStorage() {
    localStorage.clear()
    store.commit('initToken')
    //router.push('/login')
}

function subscribeTokenRefresh(cb) {
    refreshSubscribers.push(cb);
}

function onRefreshed(token) {
    refreshSubscribers.map(cb => cb(token));
}

export function replayRequests(token){
    onRefreshed(token)
    refreshSubscribers = []
}