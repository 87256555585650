import {networker} from "../http";

const contentService = {
    getNews(pagination){
        return networker.get('content/news',{
            params : {
                page : pagination.page,
                size : pagination.itemsPerPage
            }
        })
    }
}

export default contentService