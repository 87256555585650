<script>
import SubscriptionsSimpleTable from "@/components/packet/subscription/SubscriptionsSimpleTable.vue";
import ConfigInstruction from "@/components/account/ConfigInstruction.vue";
import PlaylistsDialog from "@/components/iptv/PlaylistsDialog.vue";
import PacketListExtended from "@/components/packet/PacketListExtended.vue";
import UserPacketsList from "@/components/packet/UserPacketsList.vue";
import UserPriceList from "@/components/packet/UserPriceList.vue";
import SubscriptionWidgetMixin from "@/components/packet/subscription/SubscriptionWidgetMixin";

export default {
  name: "CSSubscriptionWidget",
  mixins: [SubscriptionWidgetMixin],
  components: {
    UserPriceList,
    UserPacketsList,
    PacketListExtended,
    PlaylistsDialog,
    ConfigInstruction,
    SubscriptionsSimpleTable
  },
  props: {
    account: {
      type: Object
    },
  },
  created() {
    this.$refs.availablePacketList.load()
    this.$refs.deviceConfig.load()
  },
  data() {
    return {
      showPayPackets: false,
      nameFilter : "",
    }
  },
  methods: {
    refresh(){
      this.$emit('refresh')
      this.$refs.availablePacketList.load()
      this.$refs.deviceConfig.load()
    },
  },
}
</script>

<template>
  <div>
    <v-row no-gutters class="my-1">
      <v-btn color="pink darken-1" tile
             :block="$vuetify.breakpoint.xs"
             large @click="showPayPackets = true" ><v-icon left>mdi-cart</v-icon>{{$t('packet.pay')}}
      </v-btn>
      <v-btn color="orange" :outlined="$vuetify.breakpoint.smAndUp" tile
             :block="$vuetify.breakpoint.xs"
             v-if="hasSubs"
             @click="$refs.availablePacketList.showed = true"
             large><v-icon left>mdi-format-list-bulleted</v-icon>{{$t('packet.packets')}}
      </v-btn>
      <v-btn color="deep-orange"  :outlined="$vuetify.breakpoint.smAndUp" tile
             :block="$vuetify.breakpoint.xs"
             v-if="hasSubs"
             @click="$refs.deviceConfig.showed = true"
             large><v-icon left>mdi-tablet</v-icon>{{$t('help.device_info')}}
      </v-btn>
    </v-row>
    <user-packets-list ref="accountPackets" @loadDone="(p) => active = p" :account-id="account.id"/>
    <v-dialog v-model="showPayPackets" fullscreen>
      <v-toolbar dark>
        <v-text-field
            hide-details
            :placeholder="$t('help.search_packet_name')"
            prepend-inner-icon="mdi-magnify"
            v-model="nameFilter"
            light
            clearable
            single-line
            solo
        >
        </v-text-field>
        <v-toolbar-items>
          <v-btn icon @click="showPayPackets = false"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card>
        <v-card-text>
          <user-price-list @onBuy="refresh()" :filter="nameFilter"  :account-id="account.id"></user-price-list>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-expansion-panels light tile>
      <v-expansion-panel v-if="archiveSubscriptions.length">
        <v-expansion-panel-header class="font-weight-bold">{{$t('packet.archive')}} ({{archiveSubscriptions.length}})</v-expansion-panel-header>
        <v-expansion-panel-content>
          <subscriptions-simple-table :subscriptions="archiveSubscriptions" />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <packet-list-extended ref="availablePacketList" :account-id="account.id" :key="account.id" />
    <config-instruction   ref="deviceConfig"        :account-id="account.id" :key="account.id" />
  </div>
</template>

<style scoped>

</style>