<template>
  <div>
    <v-row>
      <v-col  cols="12" sm="6">
        <UserOverviewCard />
      </v-col>
      <v-col cols="12" sm="6">
        <balance-overview-card />
      </v-col>
      <v-col cols="12">
        <subscriptions-overview-card />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import UserOverviewCard from "./overview/UserOverviewCard";
import BalanceOverviewCard from "./overview/BalanceOverviewCard";
import SubscriptionsOverviewCard from "@/components/user/overview/SubscriptionsOverviewCard";
export default {
  name: "UserOverview",
  components: {SubscriptionsOverviewCard, BalanceOverviewCard, UserOverviewCard},
  created() {

  }
}
</script>

<style scoped>

</style>